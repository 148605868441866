import { createApp } from 'vue';
import App from './App.vue';

import router from './router'

import VueSidePanel from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';
import 'vue-select/dist/vue-select.css';


const app = createApp(App);
app.use(VueSidePanel);
app.use(router);
app.mount('#app');
