<template>
   <div class="help-container" ref="helpcontent">
      <h3>Contact data columns</h3>
      <ul>
         <li>Only some data columns are editable, which are EzzyBills data columns. </li>
         <li>The non-editable data columns come from your connected accounting software such as Xero, MYOB & QuickBooks.  You need to edit them in your accounting software. Then “Refresh” in EzzyBills under Apps.</li>
      </ul>

      <h3>Edit a single contact</h3>
      <ul>
         <li>Mouse over the contact “Name” and click to edit.  Only editable field data are shown. </li>
      </ul>

      <h3>Batch Edit</h3>
      <ul>
         <li>Select multiple lines to edit all of them.</li>
      </ul>
   </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from '@vueuse/core';

export default {
   props: [
      'closeHelpContent',
   ],
   setup(props) {
      const helpcontent = ref(null);
      onClickOutside(helpcontent, (event) => {
         if (!event.target.classList.contains('help-button') && !event.target.classList.contains('k-button-text')) {
            props.closeHelpContent();
         }
      });

      return {
         helpcontent,
      }
   },
}
</script>

<style scoped>
   .help-container {
      width: 300px;
      max-width: 300px;
      padding: 15px 30px;
   }

   .help-container h3 {
      color: #0c4271;
      margin: 0px;
   }

   .help-container ul {
      margin: 5px 0px;
      line-height: 22px;
   }
   .help-container ul li {
      margin: 5px 0px;
   }
</style>