<template>
   <div class="flex justify-content-between">
      <tabs :selected="activeTab" @select="onSelectTab" :tabs="tabs">
         <template v-slot:All>
            <contacts v-if="activeTab === 0" :type="0" :hitNewContact="hitNewContact" :changeHitNewContact="handleOpenSingleAddForm"></contacts>
         </template>
         <template v-slot:Suppliers>
            <contacts v-if="activeTab === 1" :type="1" :hitNewContact="hitNewContact" :changeHitNewContact="handleOpenSingleAddForm"></contacts>
         </template>
         <template v-slot:Customers>
            <contacts v-if="activeTab === 2" :type="2" :hitNewContact="hitNewContact" :changeHitNewContact="handleOpenSingleAddForm"></contacts>
         </template>
      </tabs>

      <div class="flex justify-content-between gap-1">
         <k-button :theme-color="'success'" @click="handleOpenSingleAddForm(true)">
            <span>New Contact</span>
         </k-button>
         <settings-dropdown :theme-color="'primary'" :items="settings" @itemclick="onSettingsItemClick">
            <span class="k-icon k-i-more-vertical k-icon-24"></span>
         </settings-dropdown>
      </div>
   </div>
</template>

<script>
import { defineComponent } from "vue";
import { Button } from '@progress/kendo-vue-buttons';
import { TabStrip } from '@progress/kendo-vue-layout';
import { DropDownButton } from "@progress/kendo-vue-buttons";

import ContactsComponent from '../components/contacts2/index.vue';
import { exportCsvContacts } from "../services";

export default defineComponent({
   name: 'ContactsPage2',
   components: {
      'tabs': TabStrip,
      'settings-dropdown': DropDownButton,
      'k-button': Button,
      'contacts': ContactsComponent,
   },
   data() {
      return {
         settings: [
            {
               text: "Import",
            },
            {
               text: "Export",
            },
         ],
         activeTab: 0,
         hitNewContact: false,
         exportId: 10,
         tabs: [
            {
               title: 'All',
               content: 'All',
            },
            {
               title: 'Suppliers',
               content: 'Suppliers',
            },
            {
               title: 'Customers',
               content: 'Customers',
            },
         ],
      }
   },
   computed: {
   },
   watch: {
   },
   methods: {
      onSelectTab(event) {
         this.activeTab = event.selected;
      },
      onSettingsItemClick(event) {
         event.item.text === 'Import' ? 
            this.$router.push(`/ContactsPage2/${event.item.text}`)
            : this.export();
      },
      handleOpenSingleAddForm(flag) {
         this.hitNewContact = flag;
      },
      async export() {
         await exportCsvContacts(this.exportId, this.activeTab);
      }
   },
})
</script>

<style scoped>
</style>
