export const contactsColumns = [
  {
    field: "Name",
    editable: false,
    title: "Name",
    width: "300px",
    sortable: true,
    locked: true,
  },
  {
    field: "Id",
    editable: false,
    title: "ID",
    width: "300px",
    sortable: false,
  },
  {
    field: "type",
    editable: false,
    title: "Type",
    width: "80px",
    sortable: false,
  },
  {
    field: "reference",
    editable: false,
    title: "Account Number",
    width: "150px",
    sortable: false,
  },
  {
    field: "address",
    editable: false,
    title: "Address",
    width: "200px",
    sortable: false,
  },
  {
    field: "email",
    editable: false,
    title: "Email",
    width: "200px",
    sortable: false,
  },
  {
    field: "phone",
    editable: false,
    title: "Phone",
    width: "150px",
    sortable: false,
  },
  {
    field: "taxNumber",
    editable: false,
    title: "Business Number",
    width: "150px",
    sortable: false,
  },
  {
    field: "bank_account",
    editable: false,
    title: "Bank Account",
    width: "150px",
    sortable: false,
  },
  {
    field: "account_code_v2",
    editable: false,
    title: "GL Account",
    width: "200px",
    sortable: false,
  },
  {
    field: "tracking",
    editable: false,
    title: "Tracking",
    width: "250px",
    sortable: false,
  },
  {
    field: "tax_code",
    editable: false,
    title: "Tax Code",
    width: "200px",
    sortable: false,
  },
  {
    field: "workflow_v2",
    editable: false,
    title: "Workflow",
    width: "200px",
    sortable: false,
  },
  {
    field: "tags",
    editable: false,
    title: "Tags",
    width: "200px",
    sortable: false,
  },
  {
    field: "region",
    editable: false,
    title: "Region",
    width: "150px",
    sortable: false,
  },
  {
    field: "city",
    editable: false,
    title: "City",
    width: "150px",
    sortable: false,
  },
  {
    field: "country",
    editable: false,
    title: "Country",
    width: "150px",
    sortable: false,
  },
];

export const contacts2Columns = [
  {
    field: "Name",
    editable: false,
    title: "Name",
    width: "300px",
    sortable: true,
    locked: true,
  },
  {
    field: "Id",
    editable: false,
    title: "ID",
    width: "300px",
    sortable: false,
  },
  {
    field: "type",
    editable: false,
    title: "Type",
    width: "80px",
    sortable: false,
  },
  {
    field: "reference",
    editable: false,
    title: "Account Number",
    width: "150px",
    sortable: false,
  },
  {
    field: "address",
    editable: false,
    title: "Address",
    width: "200px",
    sortable: false,
  },
  {
    field: "email",
    editable: false,
    title: "Email",
    width: "200px",
    sortable: false,
  },
  {
    field: "phone",
    editable: false,
    title: "Phone",
    width: "150px",
    sortable: false,
  },
  {
    field: "taxNumber",
    editable: false,
    title: "Business Number",
    width: "150px",
    sortable: false,
  },
  {
    field: "bank_account",
    editable: false,
    title: "Bank Account",
    width: "150px",
    sortable: false,
  },
  {
    field: "account_code",
    editable: false,
    title: "GL Account",
    width: "200px",
    sortable: false,
  },
  {
    field: "tracking",
    editable: false,
    title: "Tracking",
    width: "250px",
    sortable: false,
  },
  {
    field: "tax_code",
    editable: false,
    title: "Tax Code",
    width: "200px",
    sortable: false,
  },
  {
    field: "workflow_v2",
    editable: false,
    title: "Workflow",
    width: "200px",
    sortable: false,
  },
  {
    field: "tags",
    editable: false,
    title: "Tags",
    width: "200px",
    sortable: false,
  },
  {
    field: "region",
    editable: false,
    title: "Region",
    width: "150px",
    sortable: false,
  },
  {
    field: "city",
    editable: false,
    title: "City",
    width: "150px",
    sortable: false,
  },
  {
    field: "country",
    editable: false,
    title: "Country",
    width: "150px",
    sortable: false,
  },
];

export const tablePageColumns = {
  invoice: [
    { name: "No", width: "50px", resizable: false },
    { name: "Supplier Article Code" },
    { name: "Description", width: "350px" },
    { name: "Quantity" },
    { name: "Unit Price" },
    { name: "GST" },
    { name: "Total Price" },
    { name: "GL Code" },
    { name: "Tax Rate" },
    { name: "Tracking" },
    { name: "Tax Code" },
  ],
  statement: [
    { name: "No", width: "50px", resizable: false },
    {
      name: "Invoice Number",
      headerAttributes: { class: "k-text-left !k-justify-content-left" },
    },
    { name: "Description", width: "350px" },
    { name: "Start Date", displayName: "Invoice Date" },
    // { name: "Debit", },
    // { name: "Credit", },
    { name: "Total Price", displayName: "Amount" },
    // { name: "Balance", },
  ],
};

export const csvHeaders = [
  "Name",
  "ID",
  "Type",
  "Account Number",
  "Address",
  "Email",
  "Phone",
  "Business Number",
  "Bank Account",
  "GL Account",
  "Tracking",
  "Tax Code",
  "Workflow",
  "Tags",
  "Region",
  "City",
  "Country",
];

export const stateColumns = {
  356: ["Invoice Value", "Tracking Value"], // Set Tracking - 38738
  360: ["Description", "GLCode"], // Set account code - 38452
  334: ["Part", "Description", "Price"], // Invoice Price check - 38744
  352: ["Supplier Part", "Description", "My Part"], //change part - 38737
  239: ["Support Item Number", "Support Item Name"], // NDIS Extract - 38737
};

export const featureNameByState = {
  356: "Set Tracking", // Set Tracking - 38738
  360: "Set account code", // Set account code - 38452
  334: "Invoice Price check", // Invoice Price check - 38744
  352: "change part", //change part - 38737
  239: "NDIS Extract", // NDIS Extract - 38737
};

const setTrackingDummyData = [
  {
    "Invoice Value": "Organic Apple",
    "Tracking Value": "Fruit",
  },
  {
    "Invoice Value": "Orange Naval 3kg",
    "Tracking Value": "Fruit",
  },
  {
    "Invoice Value": "Milk 3L Alta Farm",
    "Tracking Value": "Dairy",
  },
  {
    "Invoice Value": "Mile 2L Sunny Farm",
    "Tracking Value": "Dairy",
  },
];

const setAccountCodeDummyData = [
  {
    Description: "Meals on Wheels",
    GLCode: 260,
  },
  {
    Description: "Nail Stainless Steel 40mm",
    GLCode: 310,
  },
  {
    Description: "Bread Naan 300g",
    GLCode: 320,
  },
];

const invoicePriceCheckDummyData = [
  {
    Part: 831,
    Description: "DRILL SET SUTTON 15 PC IMP",
    Price: 10,
  },
  {
    Part: 1234,
    Description: "Drill Widgets",
    Price: 2.21,
  },
  {
    Part: 1258,
    Description: "DRILL SET SUTTON 27 PC METRIC",
    Price: 1.56,
  },
];

const changePartDummyData = [
  {
    "Supplier Part": "1892343",
    Description: "70mm metric oil",
    "My Part": "1ASX700",
  },
  {
    "Supplier Part": "1892342",
    Description: "60mm metric oil",
    "My Part": "ASX600",
  },
  {
    "Supplier Part": "1892341",
    Description: "50mm metric oil",
    "My Part": "ASX500",
  },
  {
    "Supplier Part": "1dpszah",
    Description: "Deep Part standard zah",
    "My Part": "5678",
  },
  {
    "Supplier Part": "188123",
    Description: "Nail 40mm steel",
    "My Part": "AP345",
  },
  {
    "Supplier Part": "188124",
    Description: "Nail 50mm steel",
    "My Part": "AP346",
  },
  {
    "Supplier Part": "188125",
    Description: "Nail 60mm steel",
    "My Part": "AP347",
  },
];

const ndisExtractDummyData = [
  {
    "Support Item Number": "physio650",
    "Support Item Name": "Physio Group Ex",
  },
  {
    "Support Item Number": "homev800",
    "Support Item Name": "Home visit",
  },
];

export const dummyDataByState = {
  356: setTrackingDummyData,
  360: setAccountCodeDummyData,
  334: invoicePriceCheckDummyData,
  352: changePartDummyData,
  239: ndisExtractDummyData,
};

export function arrayOfObjectsToCSV(data) {
  const csvRows = [];

  /* Using push() method we push fetched
        data into csvRows[] array */
  csvRows.push(csvHeaders.join(","));

  // Loop to get value of each objects key
  for (const row of data) {
    const values = csvHeaders.map((header) => {
      const headerField = contactsColumns.find(
        (column) => column.title === header
      );
      const val =
        row[
          headerField.field === "workflow_v2" ? "workflow" : headerField.field
        ];
      return `"${val}"`;
    });

    // To add, separator between each value
    csvRows.push(values.join(","));
  }

  /* To add new line for each objects values
        and this return statement array csvRows
        to this function.*/
  return csvRows.join("\n");
}

export function validateCSVHeader(header) {
  // Remove special characters and capitalize the header
  const cleanedHeader = header.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

  // Check if the cleaned and capitalized header exists in the reference headers
  return csvHeaders.find((refHeader) => {
    const cleanedRefHeader = refHeader
      .replace(/[^a-zA-Z0-9]/g, "")
      .toUpperCase();

    return cleanedRefHeader === cleanedHeader;
  });
}

export function getVerfiedHeaders(headers) {
  return headers.map((header) => validateCSVHeader(header));
}
