import { arrayOfObjectsToCSV } from "./utils";

export const updateContacts = async (apiKey, exportId, body) => {
  const urlWithParams = new URL(
    `${process.env.VUE_APP_BASE_URL}${`updateContacts`}`
  );
  urlWithParams.searchParams.append("APIKey", apiKey);
  urlWithParams.searchParams.append("target", exportId);

  await fetch(urlWithParams.href, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });
};

export const fetchData = async (documentId) => {
  const urlWithParams = new URL(
    `${process.env.VUE_APP_BASE_URL}${`getInvoiceRows`}`
  );
  urlWithParams.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);
  urlWithParams.searchParams.append("invoiceid", documentId);

  return await fetch(urlWithParams.href, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("data", data);
      return data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getAllowedColumns = async () => {
  const urlWithParams = new URL(
    `${process.env.VUE_APP_BASE_URL}${`getInvoiceColumns`}`
  );
  urlWithParams.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);

  return await fetch(urlWithParams.href, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      const columns = data.columns ? data.columns.map(column => column.name) : [];
      return columns;      
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const saveData = async (invoiceid, body) => {
  const urlWithParams = new URL(
    `${process.env.VUE_APP_BASE_URL}${`saveInvoiceRows`}?invoiceid=${invoiceid}`
  );
  urlWithParams.searchParams.append("APIKey", process.env.VUE_APP_API_KEY); 

  await fetch(urlWithParams.href, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });
};

export const importCsvContacts = async (apiKey, exportId, body) => {
  const urlWithParams = new URL(
    `${process.env.VUE_APP_BASE_URL}${`addContacts`}`
  );
  urlWithParams.searchParams.append("APIKey", apiKey);
  urlWithParams.searchParams.append("target", exportId);

  await fetch(urlWithParams.href, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });
};

export function exportCsvContacts(exportId, type) {
  try {
    // contacts fetching to export
    const urlWithParams = new URL(
      `${
        process.env.VUE_APP_BASE_URL
      }${`getContactsPagedFilter?customertype=${type}&target=${exportId}&page=0&page_size=1000&ascending=1&filter=`}`
    );
    urlWithParams.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);

    fetch(urlWithParams.href, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const updatedContacts = [];

        data.contacts.map((contact) => {
          Object.keys(contact).map((c) => {
            if (
              contact[c] === "null" ||
              contact[c] === null ||
              contact[c] === 0
            ) {
              contact[c] = "";
            }
          });
          delete contact.modified;
          delete contact.selected;
          updatedContacts.push(contact);
        });

        const csvData = arrayOfObjectsToCSV([...updatedContacts]);

        var blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, "exported_contacts");
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "exported_contacts");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  } catch (error) {
    console.log("error", error);
  }
}

export function updateSupplier(docId, supplier) {
  const urlWithParams = new URL(
    `${process.env.VUE_APP_BASE_URL}setSupplier?invoiceid=${encodeURIComponent(docId)}&name=${encodeURIComponent(supplier)}`
  );
  
  urlWithParams.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);

  return fetch(urlWithParams.href, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    },
  });

}

// Implementing getSupplierData function
export const getSupplierData = async (documentId) => {
  const urlWithParams = new URL(
    `${process.env.VUE_APP_BASE_URL}${`getFormData`}`
  );
  urlWithParams.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);
  urlWithParams.searchParams.append("invoiceid", documentId);

  return await fetch(urlWithParams.href, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Supplier data", data);
      return data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// Implementing getSuppliersList function
export const getSuppliersList = async (filter = '') => {
  const urlWithParams = new URL(
    `${process.env.VUE_APP_BASE_URL}${`getMySuppliersFilter2`}`
  );

  urlWithParams.searchParams.append("filter", filter);
  urlWithParams.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);
  urlWithParams.searchParams.append("customertype", 1);

  return await fetch(urlWithParams.href, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Suppliers list", data);
      return data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// Implementing updateFormData function
export const updateFormData = async (invoiceid, body) => {
  const urlWithParams = new URL(
    `${process.env.VUE_APP_BASE_URL}${`updateFormData`}?invoiceid=${invoiceid}`
  );
  urlWithParams.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);

  await fetch(urlWithParams.href, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });
};

