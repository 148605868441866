<template>
   <!-- Top toolbar -->
   <div class="toolbar">
      <div class="toolbar-left">
         <k-input class="global-search" :placeholder="'Search'" :icon-name="'search'" v-model="searchText"
            :show-clear-button="true">
         </k-input>
         <div class="selected-count">
            <span>{{ selectedContacts.length }} Selected</span>
         </div>
         <kbutton :theme-color="'primary'" :disabled="selectedContacts.length === 0"
            @click="handleOpenBatchEditForm(true)">Batch
            Edit</kbutton>
         <kbutton ref="help-button" class="help-button" :fill-mode="'clear'" @click="showHelpContent = !showHelpContent">
            <span class="k-icon k-i-question k-question-lg"></span> Help</kbutton>
         <Popup :anchor="'help-button'" :show="showHelpContent" :popup-class="'help-content'">
            <help-content :closeHelpContent="() => showHelpContent = !showHelpContent"></help-content>
         </Popup>
      </div>
   </div>

   <!-- Contact Grid -->
   <Grid :data-items="rows" :sortable="true" :sort="sort" :columns="columns" :selected-field="selectedField"
      :cell-render="'customCell'" @sortchange="sortChangeHandler" @selectionchange="onSelectionChange"
      @headerselectionchange="onHeaderSelectionChange" :loader="loader" :size="'small'" :resizable="true">
      <!-- Rendering custom cells -->
      <template v-slot:customCell="{ props }">
         <td v-if="props.field === 'selected'" style="text-align: center;">
            <kcheckbox :value="getCellValue(props.field, props.dataItem)"
               @change="(event) => onSelectionChange(event, props.dataItem)" />
         </td>
         <td v-if="props.field !== 'Name' && props.field !== 'selected'">
            {{ getCellValue(props.field, props.dataItem) }}
         </td>
         <td v-if="props.field === 'Name'" class="k-table-td k-grid-content-sticky" style="left: 0px; right: 0px;"
            @click="handleOpenSingleEditForm(true, props.dataItem)">
            <span class="name-cell">{{ getCellValue(props.field, props.dataItem) }}</span>
         </td>
      </template>
      <!-- no contacts found block -->
      <grid-norecords>
         <div v-if="loader === false" class="grid-no-data-found-box">
            <img src="../../assets/no-data-found.jpg" alt="no-data-found" />
            No Contacts Found!
         </div>
      </grid-norecords>
   </Grid>
   <pager :skip="skip" :take="take" :total="total" :button-count="5" :info="true" :previous-next="true" :type="'numeric'"
      :page-sizes="pageSizes" :page-size="take" @pagechange="handlePageChange">
   </pager>

   <!-- Single Edit Side Panel Form -->
   <edit-form 
      :selectedContactToEdit="selectedContactToEdit" 
      :isBatchEditForm="false"
      :openEditFormFlag="openSingleEditFormFlag" 
      :handleOpenEditForm="handleOpenSingleEditForm" 
      :saveEdit="saveSingleEdit"
      :glAccounts="glAccounts" 
      :workflows="workflows" 
      :trackings="trackings" 
      :fetchContacts="fetchContacts">
   </edit-form>

   <!-- Batch Edit Side Panel Form -->
   <edit-form 
      :selectedContactToEdit="selectedContactToEdit" 
      :isBatchEditForm="true"
      :openEditFormFlag="openBatchEditFormFlag" 
      :handleOpenEditForm="handleOpenBatchEditForm" 
      :saveEdit="saveBatchEdit"
      :glAccounts="glAccounts" 
      :workflows="workflows" 
      :trackings="trackings" 
      :fetchContacts="fetchContacts">
   </edit-form>
</template>

<script>
import { defineComponent } from "vue";
import { Grid, GridNoRecords } from '@progress/kendo-vue-grid';
import { Pager } from '@progress/kendo-vue-data-tools';
import { Button } from '@progress/kendo-vue-buttons';
import { Input, Checkbox } from '@progress/kendo-vue-inputs';
import { Popup } from '@progress/kendo-vue-popup';
import _ from 'lodash';
import { useRoute } from 'vue-router';

import EditForm from './edit-form.vue';
import HelpContent from './help-content.vue';

import { updateContacts } from "@/services";
import { contactsColumns } from "@/utils";

export default defineComponent({
   name: 'ContactsComponent',
   components: {
      Grid: Grid,
      pager: Pager,
      'Popup': Popup,
      'grid-norecords': GridNoRecords,
      'kbutton': Button,
      'kcheckbox': Checkbox,
      'edit-form': EditForm,
      'help-content': HelpContent,
      'k-input': Input,
   },
   props: [
      'type',
   ],
   setup(props) {
      return {
         contactType: props.type || 0,
      }
   },
   data() {
      return {
         exportId: 2,
         contacts: [],
         glAccounts: [],
         workflows: [],
         trackings: [],
         loader: false,
         staticColumns: [
            ...contactsColumns
         ],
         sort: [{ field: 'Name', dir: 'asc' }],
         total: 0,
         skip: 0,
         take: 50, // page size
         pageNo: 0,
         asc: 1,
         pageSizes: [50, 100, 200],
         selectedField: 'selected',
         openBatchEditFormFlag: false,
         openSingleEditFormFlag: false,
         searchText: "",
         contactUniqueIdentifier: 'Id',
         selectedContactToEdit: null,
         showHelpContent: false
      }
   },
   watch: {
      contactType(val) {
         this.contactType = val;
         this.fetchContacts();
      },
      searchText: function () {
         this.fetchContacts();
      }
   },
   computed: {
      rows() {
         return this.contacts;
      },
      areAllSelected() {
         return this.contacts.length === 0 ? false : this.contacts.findIndex(item => item.selected === false) === -1;
      },
      columns() {
         return [
            { field: 'selected', width: '50px', headerSelectionValue: this.areAllSelected },
            ...this.staticColumns
         ]
      },
      selectedContacts() {
         return this.contacts.filter((item) => item[this.selectedField] === true);
      },
   },
   methods: {
      onSelectTab(event) {
         this.activeTab = event.selected;
      },
      getCellValue(fieldName, dataItem) {
         const path = fieldName.split('.');
         let data = dataItem;
         path.forEach((p) => {
            data = data ? data[p] : undefined;
         });
         return data;
      },
      sortChangeHandler(e) {
         this.loader = true;
         if (e.sort.length > 0) {
            this.sort = e.sort;
            this.asc = e.sort[0]?.dir === 'asc' ? 1 : 0;
         } else {
            this.sort = [{ field: 'Name', dir: 'asc' }];
            this.asc = 1;
         }
         this.fetchContacts();
      },
      onHeaderSelectionChange(event) {
         let checked = event.event.target.checked;
         const updatedSelectedContacts = this.contacts.map((item) => { return { ...item, selected: checked } });
         this.contacts = [...updatedSelectedContacts];
      },
      onSelectionChange(event, dataItem) {
         const updatedSelectedContacts = this.contacts.map((contact) => {
            if (dataItem[this.contactUniqueIdentifier] === contact[this.contactUniqueIdentifier])
               return { ...contact, selected: event.value };
            else return contact;
         });
         this.contacts = [...updatedSelectedContacts];
      },
      handlePageChange(event) {
         this.skip = event.skip;
         this.take = event.take;
         this.pageNo = (event.skip / event.take);
         this.fetchContacts();
      },

      handleOpenBatchEditForm(flag) {
         this.openBatchEditFormFlag = flag;
         this.openSingleEditFormFlag = false;
      },
      handleOpenSingleEditForm(flag, dataItem) {
         if (!flag) {
            this.selectedContactToEdit = null;
         } else {
            this.selectedContactToEdit = dataItem;
         }
         this.openBatchEditFormFlag = false;
         this.openSingleEditFormFlag = flag;
      },

      async saveSingleEdit(data) {
         const updatedContacts = [];
         const updatedContact = { ...this.selectedContactToEdit, ...data, modified: 2 };

         delete updatedContact.selected;
         delete updatedContact.workflow_v2;
         delete updatedContact.account_code_v2;

         updatedContacts.push(updatedContact);
         const body = [...updatedContacts];

         await updateContacts(process.env.VUE_APP_API_KEY, this.exportId, body);
      },
      async saveBatchEdit(data) {
         const updatedContacts = [];
         this.contacts.map((contact) => {
            if (contact[this.selectedField] === true) {
               const updatedContact = { ...contact, ...data, modified: 2 };
               delete updatedContact.selected;
               delete updatedContact.workflow_v2;
               delete updatedContact.account_code_v2;

               updatedContacts.push(updatedContact);
            }
         });
         const body = [...updatedContacts];

         await updateContacts(process.env.VUE_APP_API_KEY, this.exportId, body);
      },

      fetchContacts: _.debounce(async function () {
         this.loader = true;
         try {
            // workflows url
            const apiUrlWithParams1 = new URL(
               `${process.env.VUE_APP_BASE_URL}${`getWorkflows`}`
            );
            apiUrlWithParams1.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);

            // glcodes url
            const apiUrlWithParams2 = new URL(
               `${process.env.VUE_APP_BASE_URL}${`getGLCodes2`}`
            );
            apiUrlWithParams2.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);

            // trackings url
            const apiUrlWithParams3 = new URL(
               `${process.env.VUE_APP_BASE_URL}${`getTracking2`}`
            );
            apiUrlWithParams3.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);

            const options = {
               method: "GET",
               mode: "cors",
               credentials: "include",
               headers: {
                  Accept: "application/json",
               },
            };

            if (this.workflows.length === 0) {
               await fetch(apiUrlWithParams1.href, options)
                  .then((response) => response.json())
                  .then((data) => {
                     this.workflows = [...data.myworkflows.list];
                  }).catch((error) => console.log('error', error));
            }

            if (this.glAccounts.length === 0) {
               await fetch(apiUrlWithParams2.href, options)
                  .then((response) => response.json())
                  .then((data) => {
                     this.glAccounts = [...data.list];
                  }).catch((error) => console.log('error', error));
            }

            if (this.trackings.length === 0) {
               await fetch(apiUrlWithParams3.href, options)
                  .then((response) => response.json())
                  .then((data) => {
                     const jobList = [];
                     data.list.map((tracking) => {
                        if (tracking.Job.includes('|')) {
                           let parts = tracking.Job.split('|');  // Split the string by '|'

                           let first_part = parts[0];  // Get the first part
                           let last_part = parts[parts.length - 1];  // Get the last part

                           let Job = `${first_part} ${last_part}`;
                           jobList.push(Job);
                        } else {
                           jobList.push(tracking.Job);
                        }
                     });
                     this.trackings = [...jobList];
                  }).catch((error) => console.log('error', error));
            }

            // contacts fetching
            const urlWithParams = new URL(
               `${process.env.VUE_APP_BASE_URL}${`getContactsPagedFilter?customertype=${this.contactType}&target=${this.exportId}&page=${this.pageNo}&page_size=${this.take}&ascending=${this.asc}&filter=${this.searchText}`}`
            );
            urlWithParams.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);

            fetch(urlWithParams.href, {
               method: "GET",
               mode: "cors",
               credentials: "include",
               headers: {
                  Accept: "application/json",
               },
            })
               .then((response) => response.json())
               .then((data) => {
                  const updatedContacts = [];

                  data.contacts.map((contact => {
                     const currentGlAccount = this.glAccounts.find(account => account.Code === contact.account_code);
                     const currentWorkflow = this.workflows.find(workflow => workflow.workflowID === contact.workflow);
                     if (currentGlAccount) {
                        contact.account_code_v2 = currentGlAccount.Name + `(${currentGlAccount.Code})`;
                     }
                     if (currentWorkflow) {
                        contact.workflow_v2 = currentWorkflow.name;
                     } else {
                        contact.workflow = 0;
                     }
                     contact.selected = false;
                     updatedContacts.push(contact);
                  }));

                  this.contacts = [...updatedContacts];
                  this.total = data.number_contacts;
                  this.loader = false;
               }).catch((error) => {
                  console.log(error);
                  this.loader = false
               });

         } catch (error) {
            console.log('error', error)
         }
      }, 700),
   },
   async mounted() {
      // on mount, calling the api
      await this.fetchContacts()
   },
   created() {
      const route = useRoute();
      this.exportId = route.query.export_id;
   },
})
</script>

<style scoped>
.toolbar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
   margin-bottom: 15px;
}

.toolbar-left {
   display: flex;
   align-items: center;
   gap: 20px;
}

.global-search {
   width: 300px;
   padding: 3px;
}

.selected-count {
   display: flex;
   justify-content: center;
   align-items: center;
}

.selected-count>span {
   font-style: italic;
   color: #0c4271;
   font-weight: 700;
   font-size: 15px;
}

.selected-count .k-button::after {
   background-color: transparent;
}

.help-button {
   font-size: 17px;
}

.help-button::after {
   background-color: transparent;
}

.dialog-btn {
   max-width: 60px;
}

.k-dialog-actions {
   justify-content: end;
}</style>
