<template>
   <VueSidePanel v-model="isOpened" lock-scroll width="600px" rerender hide-close-btn ref="sideformforcontact2">
      <!-- Content of the VueSidePanel component -->
      <template #header>
         <div class="side-panel-container">
            <div class="header">
               <h2 v-if="formType === 'batch'">Batch Edit</h2>
               <h2 v-if="formType === 'edit' && selectedContact">{{ selectedContact?.Name }}</h2>
               <h2 v-if="formType === 'add' && !selectedContact">Add New Contact</h2>
               <kbutton :fill-mode="'outline'" :theme-color="'error'" @click="close"><span
                     class="k-icon k-i-close k-icon-lg"></span></kbutton>
            </div>
            <div class="body">
               <p v-if="error" class="error">{{ error }}</p>
               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Name</p>
                  <k-input style="width: 600px" class="inputField"
                     :default-value="form?.Name || ''" :show-clear-button="true" v-model="form.Name">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">ID</p>
                  <k-input style="width: 600px" class="inputField"
                     :default-value="form?.Id || ''" :show-clear-button="true" v-model="form.Id">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Type</p>
                  <dropdownlist
                     :style="{ width: '600px' }" 
                     :data-items='customerTypes'
                     :default-item="'Select type'"
                     :text-field="'type'"
                     :data-item-key="'id'"
                     :value="customerTypes.find(type => type.id === form?.type) || null"
                     @change="(event) => form.type = event.target.value.id"
                  >
                  </dropdownlist>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Account Number</p>
                  <k-input style="width: 600px" class="inputField" :disabled="clearField.reference" v-model="form.reference"
                     :default-value="form?.reference || ''" :show-clear-button="true">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Address</p>
                  <k-input style="width: 600px" class="inputField" v-model="form.address"
                     :default-value="form?.address || ''" :show-clear-button="true">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Email</p>
                  <k-input style="width: 600px" class="inputField" v-model="form.email"
                     :default-value="form?.email || ''" :show-clear-button="true">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Phone</p>
                  <k-input style="width: 600px" class="inputField" v-model="form.phone"
                     :default-value="form?.phone || ''" :show-clear-button="true">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Business Number</p>
                  <k-input style="width: 600px" class="inputField" v-model="form.taxNumber"
                     :default-value="form?.taxNumber || ''" :show-clear-button="true">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Bank Account</p>
                  <k-input style="width: 600px" class="inputField" v-model="form.bank_account"
                     :default-value="form?.bank_account || ''" :show-clear-button="true">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Workflow</p>
                  <span @click="workflowDropdownOpened = true">
                     <combobox 
                        style="width: 600px;"
                        :opened="workflowDropdownOpened"
                        :onFocus="() => { workflowDropdownOpened = true; }"
                        :onBlur="() => { workflowDropdownOpened = false; }"
                        :data-items="workflows.filter(wf => wf.name !== 'Clear this field')" 
                        :value="selectedWorkflow?.name || null"
                        :item-render="workflowListRender"
                        :allow-custom="true"
                        :filterable="true"
                        @filterchange="onWorkflowFilterChange"
                        @change="onWorkflowChange"
                     >
                        <template v-slot:workflowListTemplate="{ props }">
                           <workflow-list-render 
                              :style="props.style"
                              :class="props.itemClass" 
                              :data-item="props.dataItem"
                              @click="(ev) => props.onClick(ev)" />
                        </template>
                     </combobox>
                  </span>
               </div>

               <div v-if="formType === 'batch'">
                  <p class="fieldLabel">Workflow</p>
                  <span @click="workflowDropdownOpened = true">
                     <combobox 
                        style="width: 600px;"
                        :opened="workflowDropdownOpened"
                        :onFocus="() => { workflowDropdownOpened = true; }"
                        :onBlur="() => { workflowDropdownOpened = false; }"
                        :data-items="workflows" 
                        :value="selectedWorkflow?.name || null"
                        :item-render="workflowListRender"
                        :allow-custom="true"
                        :filterable="true"
                        @filterchange="onWorkflowFilterChange"
                        @change="onWorkflowChange"
                     >
                        <template v-slot:workflowListTemplate="{ props }">
                           <workflow-list-render 
                              :style="props.style"
                              :class="props.itemClass" 
                              :data-item="props.dataItem"
                              @click="(ev) => props.onClick(ev)" />
                        </template>
                     </combobox>
                  </span>
               </div>

               <div>
                  <p class="fieldLabel">Tracking</p>
                  <kcheckbox class="clear-checkbox" v-if="formType === 'batch'" v-model="clearField.tracking" label="Clear this field" />
                  <k-input style="width: 600px" class="inputField" :disabled="clearField.tracking" v-model="form.tracking"
                     :default-value="form?.tracking || ''" :show-clear-button="true">
                  </k-input>
               </div>
               
               <div>
                  <p class="fieldLabel">GL Account</p>
                  <kcheckbox class="clear-checkbox" v-if="formType === 'batch'" v-model="clearField.account_code" label="Clear this field" />
                  <k-input style="width: 600px" class="inputField" :disabled="clearField.account_code" v-model="form.account_code"
                     :default-value="form?.account_code || ''" :show-clear-button="true">
                  </k-input>
               </div>

               <div>
                  <p class="fieldLabel">Tax Code</p>
                  <kcheckbox class="clear-checkbox" v-if="formType === 'batch'" v-model="clearField.tax_code" label="Clear this field" />
                  <k-input style="width: 600px" class="inputField" :disabled="clearField.tax_code" v-model="form.tax_code"
                     :default-value="form?.tax_code || ''" :show-clear-button="true">
                  </k-input>
               </div>

               <div>
                  <p class="fieldLabel">Tags</p>
                  <kcheckbox class="clear-checkbox" v-if="formType === 'batch'" v-model="clearField.tags" label="Clear this field" />
                  <k-input style="width: 600px" class="inputField" :disabled="clearField.tags" v-model="form.tags"
                     :default-value="form?.tags || ''" :show-clear-button="true" placeholder="Enter 1 or multiple text blocks from the invoice, separating by |">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Region</p>
                  <k-input style="width: 600px" class="inputField" v-model="form.region"
                     :default-value="form?.region || ''" :show-clear-button="true">
                  </k-input>
               </div>

               <div v-if="formType === 'edit' || formType === 'add'">
                  <p class="fieldLabel">Country</p>
                  <k-input style="width: 600px" class="inputField" v-model="form.country"
                     :default-value="form?.country || ''" :show-clear-button="true">
                  </k-input>
               </div>
            </div>

            <kbutton :theme-color="'primary'" class="save-button" @click="saveEditForm">
               <span v-if="saveLoading === false">Save</span>
               <loader style="color: #fff" v-if="saveLoading === true" :size="'small'" type="pulsing" />
            </kbutton>
         </div>
      </template>
   </VueSidePanel>
</template>

<script>
import { defineComponent, ref } from "vue";
import { ComboBox } from '@progress/kendo-vue-dropdowns';
import { Button } from '@progress/kendo-vue-buttons';
import { Loader } from "@progress/kendo-vue-indicators";
import { Input, Checkbox } from '@progress/kendo-vue-inputs';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

import { onClickOutside } from '@vueuse/core';

import WorkflowListRender from '../shared/workflow-dropdown-list.vue';
import { updateContacts } from "@/services";

const formFieldsValue = {
   Name: "",
   type: "",
   reference: "",
   address: "",
   email: "",
   phone: "",
   taxNumber: "",
   bank_account: "",
   account_code: "",
   tracking: "",
   tax_code: "",
   tags: "",
   workflow: 0,
   region: "",
   country: "",
};

const clearValueForm = {
   Name: false,
   type: false,
   reference: false,
   address: false,
   email: false,
   phone: false,
   taxNumber: false,
   bank_account: false,
   account_code: false,
   tracking: false,
   tax_code: false,
   tags: false,
   workflow: false,
   region: false,
   country: false,
};

export default defineComponent({
   name: 'EditForm',
   components: {
      'combobox': ComboBox,
      'kbutton': Button,
      'k-input': Input,
      loader: Loader,
      'kcheckbox': Checkbox,
      'dropdownlist': DropDownList,
      'workflow-list-render': WorkflowListRender,
   },
   props: [
      'handleOpenEditForm',
      'openEditFormFlag',
      'saveEdit',
      'fetchContacts',
      'actionType',
      'selectedContactToEdit'
   ],
   data() {
      return {
         error: null,
         workflows: [],
         persistedWorkflowsData: [],
         workflowDropdownOpened: false,
         selectedWorkflow: null,
         saveLoading: false,
         form: {
            ...formFieldsValue,
         },
         clearField: {
            ...clearValueForm,
         },
         exportId: 10,
         customerTypes: [
            { id: 0, type: 'All' },
            { id: 1, type: 'Supplier' },
            { id: 2, type: 'Customer' },
         ],
         workflowListRender: 'workflowListTemplate',
      };
   },
   setup(props) {
      const sideformforcontact2 = ref(null)
      onClickOutside(sideformforcontact2, (event) => {
         if (event.target.classList.contains('vsp-overlay')) {
            props.handleOpenEditForm(false);
         }
      });

      return {
         sideformforcontact2,
         isOpened: props.openEditFormFlag,
         closeEditFormPanel: props.handleOpenEditForm,
         save: props.saveEdit,
         fetchContactList: props.fetchContacts,
         formType: props.actionType,
         selectedContact: props.selectedContactToEdit
      };
   },
   watch: {
      openEditFormFlag(val) {
         this.isOpened = val;

         if (val) {
            this.form = {
               ...formFieldsValue,
            };

            this.clearField = {
               ...clearValueForm,
            };

            this.selectedWorkflow = null;
         }
      },
      selectedContactToEdit(val) {
         if (val) {
            this.selectedContact = val;

            this.selectedWorkflow = this.workflows.find((wf) => wf.workflowID === this.selectedContact?.workflow) || null;

            this.form.Name = val.Name;
            this.form.Id = val.Id;
            this.form.type = val.type;
            this.form.reference = val.reference;
            this.form.address = val.address;
            this.form.email = val.email;
            this.form.phone = val.phone;
            this.form.taxNumber = val.taxNumber;
            this.form.bank_account = val.bank_account;
            this.form.account_code = val.account_code;
            this.form.tracking = val.tracking;
            this.form.tax_code = val.tax_code;
            this.form.tags = val.tags;
            this.form.workflow = val.workflow;
            this.form.region = val.region;
            this.form.country = val.country;
         }
      },
      actionType(val) {
         if (val) {
            this.formType = val;

            if (val === 'batch' && !this.workflows.find((workflow) => workflow.name === 'Clear this field')) {
               this.workflows = [{ workflowID: 'Clear this field', name: 'Clear this field' }, ...this.workflows];
            }
         }
      },
   },
   methods: {
      close() {
         this.isOpened = false;
         this.closeEditFormPanel(false);
      },
      async saveEditForm() {
         const formData = {};

         if (this.formType === 'add' || this.formType === 'edit') {
            if (this.form.Name === '') {
               this.error = 'Name is required';
               return;
            }
            this.error = null;
            Object.keys(this.form).map((formField) => {
               if (this.form[formField] && this.form[formField] !== 0 && formField !== 'type' && formField !== 'workflow') {
                  formData[formField] = this.form[formField];
               } else if (formField === 'workflow') {
                  if (this.selectedWorkflow) {
                     formData.workflow = this.selectedWorkflow.workflowID === 'Clear this field' ? 0 : this.selectedWorkflow.workflowID;
                  } else {
                     formData.workflow = 0;
                  }
               } else if (formField === 'type') {
                  formData[formField] = this.form[formField] || 0;
               } else {
                  formData[formField] = "";
               } 
            });

            if (this.formType === 'edit') {
               const contactToBeDeleted = { ...this.selectedContact };
               contactToBeDeleted.modified = 3;
               await updateContacts(process.env.VUE_APP_API_KEY, this.exportId, [{...contactToBeDeleted}]);
            }
         }

         else if (this.formType === 'batch') {
            Object.keys(this.form).map((formField) => {
               if (formField === 'workflow') {
                  if (this.selectedWorkflow) {
                     formData.workflow = this.selectedWorkflow.workflowID === 'Clear this field' ? 0 : this.selectedWorkflow.workflowID;
                  } else {
                     formData.workflow = 0;
                  }
               }
               else if (this.clearField[formField]) {
                  formData[formField] = null;
               } else if (this.form[formField] && this.form[formField]?.length > 0) {
                  formData[formField] = this.form[formField];
               } 
            });
         }
         this.saveLoading = true;

         await this.save({
            ...formData
         });
         setTimeout(() => {
            this.saveLoading = false;
            this.closeEditFormPanel(false);
            this.fetchContactList();
         }, 500);
      },
      onWorkflowChange(event) {
         this.selectedWorkflow = event.target.value;
         this.workflowDropdownOpened = false;
      },
      onWorkflowFilterChange(event) {
         const filter = { ...event.filter, field: 'name' };
         this.workflowsData = filterBy(this.persistedWorkflowsData.slice(), filter);
      },
      async getWorkflows() {
         // workflows url
         const apiUrlWithParams1 = new URL(
            `${process.env.VUE_APP_BASE_URL}${`getWorkflows`}`
         );
         apiUrlWithParams1.searchParams.append("APIKey", process.env.VUE_APP_API_KEY);

         const options = {
            method: "GET",
            mode: "cors",
            credentials: "include",
            headers: {
               Accept: "application/json",
            },
         };

         if (this.workflows.length === 0) {
            await fetch(apiUrlWithParams1.href, options)
               .then((response) => response.json())
               .then((data) => {
                  this.workflows = [{ workflowID: 'Clear this field', name: 'Clear this field' }, ...data.myworkflows.list];
                  this.persistedWorkflowsData = [{ workflowID: 'Clear this field', name: 'Clear this field' }, ...data.myworkflows.list];
               }).catch((error) => console.log('error', error));
         }
      }
   },
   async mounted() {
      await this.getWorkflows();
   },
});
</script>

<style scoped>
.side-panel-container {
   padding: 20px;
}
.side-panel-container .header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.side-panel-container .body {
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 20px 0;
   max-height: calc(100vh - 200px);
   overflow: hidden;
   overflow-y: auto;
}
.side-panel-container .body > div {
   width: 600px;
   margin: 0 auto;
}
.side-panel-container .fieldLabel {
   font-size: 15px;
   margin: 0;
   margin-bottom: 5px !important;
}
.side-panel-container .save-button {
   width: 60px;
   margin-top: 20px;
   margin-left: 28px;
}
.side-panel-container .inputField {
   margin-top: 4px;
}
.clear-checkbox {
   font-size: 14px !important;
   font-style: italic !important;
}
</style>
