<template>
   <div>
      <k-dialog v-if="isOpened" :title="'Please confirm'" @close="toggleDialog">
         <div :style="{ margin: '25px', textAlign: 'center' }">
            <p>Are you sure you want to delete the selected {{ selectedRows?.length }} row?
            </p>
            <p>
               <b>Please type {{ selectedRows?.length }} to confirm</b>
            </p>
            <kinput placeholder="Type here.." style="width: 300px;" class="inputField" v-model="inputConfirmation"></kinput>
         </div>
         <dialog-actions-bar>
            <kbutton @click='toggleDialog'>No</kbutton>
            <kbutton :theme-color="'primary'" :disabled="parseInt(inputConfirmation) !== selectedRows?.length" @click='clickOnYes'>
               <span v-if="deleteInProgress === false">Yes</span>
               <loader style="color: #fff" v-if="deleteInProgress === true" :size="'small'" type="pulsing" />
            </kbutton>
         </dialog-actions-bar>
      </k-dialog>
   </div>
</template>
<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
import { Button } from '@progress/kendo-vue-buttons';
import { Input } from '@progress/kendo-vue-inputs';
import { Loader } from "@progress/kendo-vue-indicators";

export default {
   name: 'DeleteConfirmation',
   components: {
      'k-dialog': Dialog,
      'dialog-actions-bar': DialogActionsBar,
      'kbutton': Button,
      'kinput': Input,
      loader: Loader,
   },
   props: [
      'openDeleteConfirmationFlag',
      'handleOpenDeleteConfirmation',
      'selectedRows'
   ],
   data() {
      return {
         inputConfirmation: '',
         deleteInProgress: false,
      }
   }, 
   setup(props) {
      return {
         isOpened: props.openDeleteConfirmationFlag,
         closeDialog: props.handleOpenDeleteConfirmation,
      }
   },
   watch: {
      openDeleteConfirmationFlag(val) {
         this.isOpened = val;
         if (!val) {
            this.inputConfirmation = '';
         }
      },
   },
   methods: {
      toggleDialog() {
         this.closeDialog(!this.isOpened);
         this.isOpened = !this.isOpened;
      },
      async clickOnYes() {
         this.$emit('deleteRow', this.selectedRows)
      }
   }
};

</script>