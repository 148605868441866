<template>
   <div class="block">
      <div class="prepare-file-import-block">
         <div class="flex justify-content-between align-items-center mb-20px">
            <h2>Step 1: Prepare file to import</h2>

            <div class="flex gap-1 align-items-center">
               <span class="k-icon k-i-question k-question-lg"></span>

               <a class="read-tutorial" href="https://www.ezzybills.com/user-guide/upload-contacts/" target="_blank">Read the tutorial</a>
            </div>
         </div>
         <p>
            It is recommended to <b><a 
               class="contact-anchor" href="/ezzybills contacts template.csv" download
            >download the CSV template</a></b> 
            or <b><a class="contact-anchor" href="#/ContactsPage2">export existing contacts</a></b> before importing.
            <br><br> 
            Open the CSV file in Excel for editing, add new contacts or modify existing ones, and save the file. 
            Please refrain from modifying the column headings as they are crucial for the import process.
         </p>
      </div>

      <div class="update-contacts-block">
         <h2 class="mb-20px">Step 2: Import contacts</h2>      

         <div class="file-drop-block">
            <b>File to upload</b> (required)
            <div
               class="dropzone"
               @dragover.prevent
               @dragenter.prevent
               @dragstart.prevent
               @drop.prevent="handleFileChange($event.dataTransfer)"
            >
               <input
                  id="file-input"
                  type="file"
                  @change="handleFileChange($event.target)"
                  required
                  ref="inputFileUpload"
                  @click="clearFile"
               />

               <img width="80" src="../assets/drag-and-drop.jpg" alt="drag-and-drop" />
               <p for="file-input" class="file-input">Click or Drag and Drop CSV file here</p>
               <div class="align-center">
                  <h3 v-if="this.file">File name: {{ fileName }}
                  <k-button v-if="this.file" :size="small" :fill-mode="'flat'" :theme-color="'error'" @click="clearFile"><span
                           class="k-icon k-i-close k-icon-lg"></span></k-button></h3>

                  <p class="error">{{ error }}</p>
               </div>
            </div>
            <span class="size-note">*2 MB max size. File should end in .csv.</span>
         </div>

         <div v-if="!errorFound && this.file" class="table-block">
            <div v-if="!uploaded" class="flex flex-column justify-content-between" style="gap: 10px;">
               <p class="upload-text">The below contacts are ready to be imported.</p>
               <b class="error">Note: Please be aware that when you import the new contacts, 
                  the existing contacts will be overwritten 
                  and permanently replaced. We recommend reviewing the new contacts carefully to ensure it 
                  contains the correct and up-to-date contact information before proceeding. Empty rows and rows without the name will be ignored.</b>
            </div>

            <table v-if="!uploaded && tableRows.length > 0">
               <thead>
                  <tr>
                     <th v-for="header in headers" :key="header">{{ header }}</th>
                  </tr>
               </thead>
               <tbody>
                  <tr v-for="row in tableRows" :key="row.id">
                     <td v-for="cell in row" :key="cell">{{ cell }}</td>
                  </tr>
               </tbody>
            </table>

            <div class="export-checkbox">
               <checkbox
                  v-model="exportContacts"
                  :label="'Export existing contacts before importing new contacts'"
               />
            </div>
            <k-button :size="'large'" :theme-color="'primary'" class="import-button" @click="uploadCsvData">
               <span v-if="uploading === false">Import</span>
               <loader style="color: #fff" v-if="uploading === true" :size="'small'" type="pulsing" />
            </k-button>
         </div>
      </div>
   </div>
</template>

<script>
import Papa from 'papaparse';
import { Button } from '@progress/kendo-vue-buttons';
import { Loader } from "@progress/kendo-vue-indicators";
import { Checkbox } from "@progress/kendo-vue-inputs";

import { importCsvContacts, exportCsvContacts } from '../services';
import { validateCSVHeader, getVerfiedHeaders, contactsColumns } from '../utils';

import _ from 'lodash';

export default {
   name: 'EditForm',
   components: {
      'k-button': Button,
      loader: Loader,
      checkbox: Checkbox
   },
   data() {
      return {
         headers: [],
         rows: [],
         file: null,
         fileName: "",
         exportContacts: false,
         error: "",
         errorFound: false,
         exportId: 10,
         uploading: false,
         uploaded: false,
      };
   },
   computed: {
      tableRows() {
         return this.rows;
      }
   },
   methods: {
      handleFileChange(event) {
         this.file = event.files[0];
         this.fileName = this.file.name;
         // File extension validation
         if (!this.file.type.includes('csv')) {
            this.errorFound = true;
            this.error = "The file must be in csv format.";
            return;
         }
         // File size validation - 2MB
         if (this.file.size / 1024 / 1024 > 2) {
            this.errorFound = true;
            this.error = "The file size must be equal and less than 2MB.";
            return;
         }

         Papa.parse(this.file, {
            header: true,
            complete: (results) => {
               let headers = results.meta.fields;

               headers = getVerfiedHeaders(headers.map((header) => header.replace(/_\d+/, '')).filter((header) => header.length !== 0));

               const headerNameAvailable = headers.find((header) => header.toUpperCase() === 'NAME');
               const headerAvailable = headers.length > 0;
               let error = "";

               if (headerNameAvailable && headerAvailable) {
                  const rows = [];
                  this.headers = headers;

                  results.data.map(d => {
                     let r = {};
                     Object.keys(d).map((key) => {
                        const verifiedHeaderKey = validateCSVHeader(key);
                        const headerField = contactsColumns.find((column) => column.title === verifiedHeaderKey)

                        if (verifiedHeaderKey) {
                           r[headerField.field === 'workflow_v2' ? 'workflow' : headerField.field] = d[key];
                        }
                     });

                     if (r.Name === "" || !r.Name) return;

                     else if (r) {
                        rows.push(r);
                     }
                  });

                  this.error = "";
                  this.rows = rows;
                  this.errorFound = false;

                  if (rows.length === 0) {
                     error = "No rows found.";
                  }
               } else {
                  if (!headerAvailable) {
                     error = error + "The headers must be provided.";
                  }

                  else if (!headerNameAvailable) {
                     error = error + "The headers must contain the name field.";
                  }
               }

               if (error.length > 0) {
                  this.errorFound = true;
                  this.error = error;
               }
            }
         });
      },
      async uploadCsvData() {
         const updatedContacts = [];
         this.uploading = true;

         if (this.exportContacts) {
            await exportCsvContacts(this.exportId, 0);
         }

         const rowsToBeImported = _.cloneDeep(this.rows);

         rowsToBeImported.map((contact) => {
            if (!contact.Name || contact.Name?.length === 0) return;
            if (!contact.Id || contact.Id?.length === 0) {
               contact.Id = contact.Name;
            }
            if (contact.workflow === "" || !contact.workflow) {
               contact.workflow = 0;
            }

            Object.keys(contact).map((c) => {
               if (contact[c] === "null" || contact[c] === "") {
                  contact[c] = "";
               } 
               if (c === 'active') {
                  contact[c] = false;
               }
               if (c === 'type') {
                  if (contact[c].length === 0 || (contact[c] != 0 && contact[c] != 1 && contact[c] != 2)) {
                     contact[c] = 0;
                  }
               }
            });

            const updatedContact = { ...contact, modified: 1 };

            delete updatedContact.id;
            delete updatedContact.name;
            delete updatedContact.selected;
            updatedContacts.push(updatedContact);
         });

         const body = [...updatedContacts];

         await importCsvContacts(process.env.VUE_APP_API_KEY, this.exportId, body);

         this.$router.push('/ContactsPage2')
         this.uploading = false;
         this.uploaded = true;
      },
      clearFile() {
         this.file = null;
         this.fileName = "";
         this.rows = [];
         this.headers = [];
         this.error = "";
         this.errorFound = false;
         this.uploaded = false;
         this.uploading = false;
         this.exportContacts = false;
         this.$refs.inputFileUpload.value = '';
      }
   }
};
</script>
<style scoped>
p {
   font-size: 15px;
   line-height: 24px;
}
h2 {
   color: #0c4271;
   font-weight: 700;
   font-size: 26px;
   margin: 0;
   padding: 0;
}
.mb-20px {
   margin-bottom: 20px;
}
.block {
   margin: auto;
   width: 900px;
}
/* Prepare file import block */
.prepare-file-import-block {
   margin: 30px 0;
}
.download-template {
   width: 270px;
   height: 40px;
   font-weight: bold;
}
.read-tutorial, .contact-anchor {
   color: blue;
   font-weight: 700;
}
/* File upload block */
.update-contacts-block {
   margin: 50px 0;
}
.file-drop-block {
   margin: 10px 0;
}
.dropzone {
  height: fit-content;
  min-height: 200px;
  max-height: 500px;
  height: auto;
  width: 860px;
  background: #fdfdfd;
  border-radius: 5px;
  border: 2px dashed darkgrey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  padding: 20px;
}
.file-input {
   margin-top: 8px;
   font-size: 16px;
}
input[type="file"] {
  position: absolute;
  opacity: 0;
  width: inherit;
  min-height: 200px;
  max-height: 400px;
  cursor: pointer;
}
.export-checkbox {
   border: 1px solid darkgrey;
   background: #fdfdfd;
   border-radius: 5px;
   padding: 15px;
   margin: 10px 0;
}
.size-note {
   color: slategrey;
   font-size: 12px;
}
.table-block {
   margin: 10px 0;
   display: flex;
   flex-direction: column;
   gap: 8px;
}
table {
   margin: 10px auto;
   color: #333;
   background: white;
   border: 1px solid grey;
   font-size: 14px;
   border-collapse: collapse;
   display: block;
   overflow-x: auto;
   white-space: nowrap;
   max-width: 900px;
}
table thead th {
   color: #777;
   background: rgba(0, 0, 0, .1);
}
table th,
table td {
   padding: .5em;
   border: 1px solid lightgrey;
}
.upload-text {
   font-size: 16px;
   padding: 0;
   margin: 0;
}
.import-button {
   width: 100px;
}
</style>