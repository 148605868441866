<template>
   <div class="help-container" ref="helpcontent">
      <h3>CSV File Manager</h3>
      <ul>
         <li>This page is for you to upload & manage csv file upload for some EzzyBills workflow tasks.</li>
         <li>In the url of this page, you will see the workflow ID (workflowid) and the task ID (state).</li>
         <li>If you need to import a csv file, click the 3-dots icon on this page and then “Import”. You will be able to download a template and create the csv file with Excel and save.  Then import.</li>
      </ul>
   </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from '@vueuse/core';

export default {
   props: [
      'closeHelpContent',
   ],
   setup(props) {
      const helpcontent = ref(null);
      onClickOutside(helpcontent, (event) => {
         if (!event.target.classList.contains('help-button') && !event.target.classList.contains('k-button-text')) {
            props.closeHelpContent();
         }
      });

      return {
         helpcontent,
      }
   },
}
</script>

<style scoped>
   .help-container {
      width: 450px;
      max-width: 450px;
      padding: 15px 30px;
   }
   .help-container h3 {
      color: #0c4271;
      margin: 0px;
   }
   .help-container p {
      margin-block-start: 5px;
      margin-block-end: 5px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
   }
   .help-container ul {
      margin: 5px 0px;
      line-height: 22px;
   }
   .help-container ul li {
      margin: 5px 0px;
   }
</style>