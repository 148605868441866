<template>
  <!-- Top toolbar -->
  <div class="toolbar">
    <div class="toolbar-left">
      <k-input
        class="global-search"
        :placeholder="'Search'"
        :icon-name="'search'"
        v-model="searchText"
        :show-clear-button="true"
      >
      </k-input>

      <div class="selected-count">
        <span>{{ selectedRows.length }} Selected</span>
      </div>

      <kbutton
        style="display: none"
        :theme-color="'primary'"
        :disabled="selectedRows.length === 0"
        @click="handleOpenBatchEditForm(true)"
      >
        Batch Edit
      </kbutton>

      <!-- <kbutton :theme-color="'primary'" :disabled="selectedRows.length === 0" @click="handleOpenDeleteConfirmation(true)">
        Edit
      </kbutton> -->

      <kbutton
        :theme-color="'error'"
        :disabled="selectedRows.length === 0"
        @click="handleOpenDeleteConfirmation(true)"
      >
        Delete
      </kbutton>

      <kbutton ref="help-button" class="help-button" :fill-mode="'clear'" @click="showHelpContent = !showHelpContent">
        <span class="k-icon k-i-question k-question-lg"></span>
        Help
      </kbutton>

      <Popup
        :anchor="'help-button'"
        :show="showHelpContent"
        :popup-class="'help-content'"
      >
        <help-content
          :closeHelpContent="() => (showHelpContent = !showHelpContent)"
        ></help-content>
      </Popup>
    </div>
  </div>
  <!-- Contact Grid -->
  <Grid
    :data-items="rows"
    :sortable="true"
    :sort="sort"
    :columns="columns"
    :selected-field="selectedField"
    :cell-render="'customCell'"
    @sortchange="sortChangeHandler"
    @selectionchange="onSelectionChange"
    @headerselectionchange="onHeaderSelectionChange"
    :loader="loader"
    :size="'small'"
    :resizable="true"
  >
    <!-- Rendering custom cells -->
    <template v-slot:customCell="{ props }">
      <td v-if="props.field === 'selected'" style="text-align: center">
        <kcheckbox
          :value="getCellValue(props.field, props.dataItem)"
          @change="(event) => onSelectionChange(event, props.dataItem)"
        />
      </td>

      <td v-if="props.field !== firstColumn && props.field !== 'selected'">
        {{ getCellValue(props.field, props.dataItem) }}
      </td>

      <td
        v-if="props.field === firstColumn"
        class="k-table-td"
        style="left: 0px; right: 0px"
        @click="handleOpenSingleEditForm(true, props.dataItem)"
      >
        <span class="name-cell">{{
          getCellValue(props.field, props.dataItem)
        }}</span>
      </td>
    </template>

    <!-- no csvData found block -->
    <grid-norecords>
      <div v-if="loader === false" class="grid-no-data-found-box">
        <img src="../../assets/no-data-found.jpg" alt="no-data-found" />
        No Data Found!
      </div>
    </grid-norecords>
  </Grid>

  <pager
    :skip="skip"
    :take="take"
    :total="totalRowCount"
    :button-count="5"
    :info="true"
    :previous-next="true"
    :type="'numeric'"
    :page-sizes="pageSizes"
    :page-size="take"
    @pagechange="handlePageChange"
  >
  </pager>

  <!-- Single Add Side Panel Form -->
  <edit-form
    :key="'add'"
    :actionType="'add'"
    :openEditFormFlag="openSingleFormFlag"
    :handleOpenEditForm="handleOpenSingleAddForm"
    :formFieldsValue="formFieldsValue"
    @saveEditForm="saveEditForm"
  ></edit-form>

  <!-- Single Edit Side Panel Form -->
  <edit-form
    :key="'edit'"
    :actionType="'edit'"
    :openEditFormFlag="openSingleEditFormFlag"
    :handleOpenEditForm="handleOpenSingleEditForm"
    :formFieldsValue="formFieldsValue"
    @saveEditForm="saveEditForm"
  ></edit-form>

  <!-- Batch Edit Side Panel Form -->
  <edit-form
    :key="'batch'"
    :actionType="'batch'"
    :openEditFormFlag="openBatchEditFormFlag"
    :handleOpenEditForm="handleOpenBatchEditForm"
    :selectedContactToEdit="selectedContactToEdit"
  ></edit-form>

  <!-- Delete Confirmation -->
  <delete-confirmation
    :selectedRows="selectedRows"
    :openDeleteConfirmationFlag="openDeleteConfirmationFlag"
    :handleOpenDeleteConfirmation="handleOpenDeleteConfirmation"
    @deleteRow="deleteRow"
  ></delete-confirmation>
</template>

<script>
import Papa from "papaparse";
import { defineComponent } from "vue";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { Pager } from "@progress/kendo-vue-data-tools";
import { Button } from "@progress/kendo-vue-buttons";
import { Input, Checkbox } from "@progress/kendo-vue-inputs";
import { Popup } from "@progress/kendo-vue-popup";

import EditForm from "./edit-form.vue";
import DeleteConfirmationDialog from "./delete-confirmation-dialog.vue";
import HelpContent from "./help-content.vue";

// import _ from "lodash";
// import { updateContacts } from "@/services";
import { featureNameByState, stateColumns } from "@/utils";

export default defineComponent({
  name: "csv-loader-component",

  components: {
    Grid: Grid,
    pager: Pager,
    Popup: Popup,
    "grid-norecords": GridNoRecords,
    kbutton: Button,
    kcheckbox: Checkbox,
    "edit-form": EditForm,
    "delete-confirmation": DeleteConfirmationDialog,
    "help-content": HelpContent,
    "k-input": Input,
  },

  props: ["hitAddNewData", "changeHitAddNewData", "downloadCSV"],

  data() {
    return {
      windowWidth: 0,
      workFlowId: "",
      state: "",
      fileName: "",
      invoiceId: "",
      firstColumn: "",
      staticColumns: [],
      tableData: [],
      csvData: [],
      indexOfSelectedData: "",
      headerSelectionValue: false,
      formFieldsValue: {},
      exportId: 10,
      workflows: [],
      loader: false,
      sort: [{ field: "Name", dir: "asc" }],

      skip: 0,
      take: 50,
      pageNo: 0,
      asc: 1,
      pageSizes: [50, 100, 200],

      selectedField: "selected",
      openBatchEditFormFlag: false,
      openSingleEditFormFlag: false,
      openSingleFormFlag: false,
      openDeleteConfirmationFlag: false,
      contactUniqueIdentifier: "Id",
      selectedContactToEdit: null,
      searchText: "",
      showHelpContent: false,
      getInterval: null,
    };
  },

  created() {
    this.workFlowId = this.$route.query.workflowid;
    this.state = this.$route.query.state;
    this.loader = this.$route.query.loader == ("true" || true) ? true : false;
  },

  async mounted() {
    this.windowWidth = screen.width - 88;
    if (this.loader) {
      this.getInterval = setInterval(async () => {
        await this.fetchExistingCSVFile();
      }, 2000);
    } else {
      await this.fetchExistingCSVFile();
    }

    if (this.csvData && this.csvData.length <= 0) {
      let columns = stateColumns[this.state]
      let widthOfColumn = this.windowWidth / columns.length
      columns.map((column, i) => {
        let obj
        if (i == 0) {
          this.firstColumn = column
          obj = { field: column, editable: false, title: column, width: `${widthOfColumn}px`, sortable: false }
        } else {
          obj = { field: column, editable: false, title: column, width: `${widthOfColumn}px`, sortable: false }
        }
        this.staticColumns.push(obj)
      })
    }
  },

  watch: {
    rows: function (newVal) {
      if (newVal && newVal.length > 0) {
        this.loader = false;
        clearInterval(this.getInterval);
        this.$router.push(
          `/csvloader?workflowid=${this.workFlowId}&state=${this.state}&loader=false`
        );
      }
    },
    searchText: function (searchTerm) {
      const results = this.csvData.filter((item) => {
        for (const key in item) {
          if (
            typeof item[key] === "string" &&
            item[key].toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });

      this.tableData = results;
      this.resetPagination();
    },

    downloadCSV: async function (newVal) {
      if (newVal === true) {
        await this.exportCSV();
      }
    },

    hitAddNewData: function (val) {
      if (val) {
        let formFieldsValue = {};
        stateColumns[this.state].map((column) => {
          formFieldsValue[column] = "";
        });
        this.formFieldsValue = formFieldsValue;
        this.handleOpenSingleAddForm(true);
      }
    },
  },

  computed: {
    rows() {
      let newArr = [];
      for (let i = this.skip; i < this.skip + this.take; i++) {
        if (this.tableData[i]) {
          newArr.push(this.tableData[i]);
        }
      }
      return newArr;
    },

    totalRowCount() {
      return this.tableData.length;
    },

    columns() {
      if (this.staticColumns && this.staticColumns.length) {
        return [
          {
            field: "selected",
            width: "50px",
            headerSelectionValue: this.headerSelectionValue,
          },
          ...this.staticColumns,
        ];
      } else {
        return [
          {
            field: "",
            width: this.windowWidth + 50 + "px",
            headerSelectionValue: this.headerSelectionValue,
          },
        ];
      }
    },

    selectedRows() {
      return this.tableData.filter((item) => item[this.selectedField] === true);
    },
  },

  methods: {
    resetPagination() {
      this.skip = 0;
      this.take = 50;
      this.pageNo = 0;
    },

    async fetchExistingCSVFile(callFetchExistingCSVFileURL = true) {
      try {
        const url = `${process.env.VUE_APP_BASE_URL}getWorkflowSettings?workflowid=${this.workFlowId}&APIKey=${process.env.VUE_APP_API_KEY}`;
        const options = {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        };

        await fetch(url, options)
          .then((response) => response.json())
          .then(async (data) => {
            if (data && data.setting && data.setting.length) {
              const parsedData = JSON.parse(data.setting);
              // console.log("parsedData: ", parsedData)

              const matchedStateData = parsedData.settings.find(
                (setting) => setting.state == this.state
              );
              if (matchedStateData) {
                // console.log("matchedStateData: ", matchedStateData)
                const data = matchedStateData.keywords;

                if (data && data[0] && data[0].value && data[1] && data[1].value && !['False', 'True'].includes(data[1].value) && !['False', 'True'].includes(data[0].value)) {
                  this.fileName = data[0].value;
                  this.invoiceId = data[1].value;

                  if (callFetchExistingCSVFileURL) {
                    await this.fetchExistingCSVFileURL(this.invoiceId);
                  }
                } else {
                  console.log("Invalid invoice_id");
                  throw new Error("Invalid invoice_id");
                }
              } else {
                console.log("File not found!");
                throw new Error("File not found!");
              }
            }
          })
          .catch((error) => console.log("error", error));
      } catch (error) {
        console.log("error: ", error);
      }
    },

    async fetchExistingCSVFileURL(invoiceid) {
      try {
        const url = `${process.env.VUE_APP_BASE_URL}getFormData?APIKey=${process.env.VUE_APP_API_KEY}&invoiceid=${invoiceid}`;
        const options = {
          method: "GET",
          mode: "cors",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        };

        await fetch(url, options)
          .then((response) => response.json())
          .then(async (data) => {
            // console.log("data: ", data)
            if (data && data.blob_url && data.blob_url.length) {
              // console.log("data.blob_url: ", data.blob_url)
              await this.parseCSVFile(data.blob_url);
            } else {
              console.log("blob_url not found!");
            }
          })
          .catch((error) => console.log("error", error));
      } catch (error) {
        console.log("error: ", error);
      }
    },

    async parseCSVFile(url) {
      try {
        let firstColumn = "";
        let headers = [];
        let items = [];

        const setCSVData = (firstColumn, headers, items) => {
          this.firstColumn = firstColumn;
          this.staticColumns = headers;
          this.csvData = this.tableData = items;
          this.resetPagination();
        };

        const calculateColWidth = (obj) => {
          let columns = Object.keys(obj).length - 1;
          return this.windowWidth / columns;
        };

        const removeFileName = () => {
          this.fileName = ""
        };

        Papa.parse(url, {
          download: true,
          header: true,
          step: function (row) {
            if (
              row &&
              row.data &&
              Object.keys(row.data) &&
              Object.keys(row.data)[0] &&
              row.data[Object.keys(row.data)[0]] &&
              row.data[Object.keys(row.data)[0]] !== ""
            ) {
              items.push({
                selected: false,
                ...row.data,
              });
            }
          },
          complete: function () {
            if (items.length) {
              let index = 0;
              let widthOfColumn = calculateColWidth(items[0]);
              for (const key in items[0]) {
                if (key !== "selected") {
                  let obj;
                  if (index == 0) {
                    firstColumn = key;
                    obj = {
                      field: key,
                      editable: false,
                      title: key,
                      width: `${widthOfColumn}px`,
                      sortable: false,
                    };
                  } else {
                    obj = {
                      field: key,
                      editable: false,
                      title: key,
                      width: `${widthOfColumn}px`,
                      sortable: false,
                    };
                  }
                  headers.push(obj);
                  index++;
                }
              }
            }
            setCSVData(firstColumn, headers, items);
          },
          error: function(error) {
            console.log("Parse error: ", error.message)
            removeFileName()
          }
        });
      } catch (error) {
        console.log("error: ", error);
      }
    },

    async convertToBytes(csvData) {
      const blob = new Blob([csvData], { type: "text/csv" });
      return new Uint8Array(await blob.arrayBuffer());
    },

    async saveEditForm(data) {
      try {
        let formType = data.type;
        let formData = data.data;

        switch (formType) {
          case "add":
            this.csvData.push(formData);
            break;

          case "edit":
            this.csvData[this.indexOfSelectedData] = formData;
            break;
        }
        this.tableData = this.csvData;
        await this.saveFormData();
      } catch (error) {
        console.log("error: ", error);
      }
    },

    async saveFormData() {
      try {
        const newData = this.csvData.filter((item) => delete item["selected"]);
        const csvData = Papa.unparse(newData);
        const bytesData = await this.convertToBytes(csvData);
        const body = Object.values(bytesData);

        let url = "";
        if (this.fileName && this.fileName != "") {
          url = `${process.env.VUE_APP_BASE_URL}updateWorkflowCSV?APIKey=${process.env.VUE_APP_API_KEY}&invoiceid=${this.invoiceId}&state=${this.state}&workflowid=${this.workFlowId}`;
        } else {
          this.fileName = featureNameByState[this.state];
          url = `${process.env.VUE_APP_BASE_URL}addWorkflowCSV?APIKey=${process.env.VUE_APP_API_KEY}&state=${this.state}&workflowid=${this.workFlowId}`;
        }

        const options = {
          method: "POST",
          mode: "cors",
          headers: new Headers({
            "content-type": "application/json",
            accept: "application/json",
          }),
          credentials: "include",
          body: JSON.stringify({
            PictureName: this.fileName.includes(".csv")
              ? this.fileName
              : `${this.fileName}.csv`,
            PictureStream: body,
          }),
        };

        await fetch(url, options)
          .then((response) => response.json())
          .then(async (data) => {
            // console.log("data: ", data.url);
            if (data && data.url && data.url !== '') {
              await this.fetchExistingCSVFile(false);
              await this.parseCSVFile(data.url);
            } else {
              await this.fetchExistingCSVFile();
            }
          })
          .catch((error) => console.log("error", error));
      } catch (error) {
        console.log("error: ", error);
      }
    },

    async deleteRow(selectedRows) {
      this.tableData = this.csvData = this.csvData.filter(
        (item) => !selectedRows.includes(item)
      );

      if (!this.csvData.length) {
        let obj = {};
        stateColumns[this.state].map((column) => {
          obj[column] = "";
        });
        this.csvData.push(obj);
      }

      await this.saveFormData();
      this.headerSelectionValue = false;
      this.openDeleteConfirmationFlag = false;

      if (!this.csvData.length) {
        this.csvData = [];
      }
    },

    async exportCSV() {
      const data = this.csvData.filter((item) => delete item["selected"]);
      const csvData = Papa.unparse(data, { header: true });

      var blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, "exported_data");
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "exported_data");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      this.$emit("updateDownloadCSVValue", false);
    },

    getCellValue(fieldName, dataItem) {
      const path = fieldName.split(".");
      let data = dataItem;
      path.forEach((p) => {
        data = data ? data[p] : undefined;
      });
      return data;
    },

    onHeaderSelectionChange(event) {
      let checked = event.event.target.checked;

      for (let i = this.skip; i < this.skip + this.take; i++) {
        if (this.csvData[i]) {
          this.tableData[i] = this.csvData[i] = {
            ...this.csvData[i],
            selected: checked,
          };
        }
      }
    },

    onSelectionChange(event, dataItem) {
      this.headerSelectionValue = false;
      const updatedSelectedContacts = this.csvData.map((row) => {
        if (row === dataItem) {
          return { ...row, selected: event.value };
        } else {
          return row;
        }
      });
      this.tableData = this.csvData = [...updatedSelectedContacts];

      const allSelected = this.csvData.filter((row) => !row.selected);
      if (allSelected && allSelected.length == 0) {
        this.headerSelectionValue = true;
      } else {
        this.headerSelectionValue = false;
      }
    },

    handleOpenDeleteConfirmation(flag) {
      this.openDeleteConfirmationFlag = flag;
    },

    handleOpenBatchEditForm(flag) {
      this.selectedContactToEdit = null;
      this.openBatchEditFormFlag = flag;
      this.openSingleEditFormFlag = false;
    },

    handleOpenSingleAddForm(flag) {
      this.openSingleFormFlag = flag;
      this.changeHitAddNewData(flag);
    },

    handleOpenSingleEditForm(flag, dataItem) {
      if (!flag) {
        this.selectedContactToEdit = null;
      } else {
        // indexOfSelectedData
        this.indexOfSelectedData = this.csvData.findIndex(
          (row) => row == dataItem
        );
        let formFieldsValue = {};
        for (const key in dataItem) {
          if (key !== "selected") {
            formFieldsValue[key] = dataItem[key];
          }
        }
        this.formFieldsValue = formFieldsValue;
      }
      this.openBatchEditFormFlag = false;
      this.openSingleEditFormFlag = flag;
    },

    sortChangeHandler(e) {
      this.loader = true;
      if (e.sort.length > 0) {
        this.sort = e.sort;
        this.asc = e.sort[0]?.dir === "asc" ? 1 : 0;
      } else {
        this.sort = [{ field: "Name", dir: "asc" }];
        this.asc = 1;
      }
    },

    handlePageChange(event) {
      this.skip = event.skip;
      this.take = event.take;
      this.pageNo = event.skip / event.take;
    },
  },
});
</script>

<style scoped>
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.toolbar-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.global-search {
  width: 300px;
  padding: 3px;
}

.selected-count {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-count > span {
  font-style: italic;
  color: #0c4271;
  font-weight: 700;
  font-size: 15px;
}

.selected-count .k-button::after {
  background-color: transparent;
}

.help-button {
  font-size: 17px;
}

.help-button::after {
  background-color: transparent;
}

.dialog-btn {
  max-width: 60px;
}

.k-dialog-actions {
  justify-content: end;
}
</style>