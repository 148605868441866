<template>
   <div class="help-container" ref="helpcontent">
      <h3>Contact data fields</h3>
      <p>This contact list is for EzzyBills accounts that do not have a connected accounting or job/inventory software.</p>
      <ul>
         <li>Required fields: <b>“Name”</b>.  Nothing else.</li>
         <li><b>“Type”</b>: 1 supplier; 2  customer; 0 both.</li>
         <li>The <b>'ID'</b> field must be kept unique. If you do not enter a value in the 'ID' field, it will be automatically set as the value from the 'Name' field.</li>
         <li>The <b>'Business number'</b> is important for supplier matching. Please provide data for this field, without any spaces. For UK users, there is no need to provide the country code of the VAT number. </li>
         <li>In the <b>'Address'</b> field: copy a unique supplier address (select one unique address line from the bills, not the entire address block) and paste it. </li>
         <li>Find more at <a href="https://www.ezzybills.com/user-guide/upload-contacts/" target="_blank">https://www.ezzybills.com/user-guide/upload-contacts/</a></li>
      </ul>

      <h3>Edit a single contact</h3>
      <ul>
         <li>Mouse over the contact “Name” and click to edit. Only editable field data are shown.</li>
      </ul>

      <h3>Batch Edit</h3>
      <ul>
         <li>Select multiple lines to batch edit some data fields.</li>
      </ul>
   </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from '@vueuse/core';

export default {
   props: [
      'closeHelpContent',
   ],
   setup(props) {
      const helpcontent = ref(null);
      onClickOutside(helpcontent, (event) => {
         if (!event.target.classList.contains('help-button') && !event.target.classList.contains('k-button-text')) {
            props.closeHelpContent();
         }
      });

      return {
         helpcontent,
      }
   },
}
</script>

<style scoped>
   .help-container {
      width: 450px;
      max-width: 450px;
      padding: 15px 30px;
   }
   .help-container h3 {
      color: #0c4271;
      margin: 0px;
   }
   .help-container p {
      margin-block-start: 5px;
      margin-block-end: 5px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
   }
   .help-container ul {
      margin: 5px 0px;
      line-height: 22px;
   }
   .help-container ul li {
      margin: 5px 0px;
   }
</style>