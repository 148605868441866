<template>
   <VueSidePanel v-model="isOpened" lock-scroll width="600px" rerender hide-close-btn ref="sideformforcontact2">
      <!-- Content of the VueSidePanel component -->
      <template #header>
         <div class="side-panel-container">

            <div class="header">
               <h2 v-if="formType === 'batch'">Batch Edit</h2>
               <h2 v-if="formType === 'edit' && selectedContact">{{ selectedContact?.Name }}</h2>
               <h2 v-if="formType === 'add' && !selectedContact">Add New</h2>
               <kbutton :fill-mode="'outline'" :theme-color="'error'" @click="close"><span
                  class="k-icon k-i-close k-icon-lg"></span></kbutton>
            </div>

            <div class="body">
               <p v-if="error" class="error">{{ error }}</p>
               <p></p>
               <div v-for="(field, key) in form" :key="key">
                  <div v-if="formType === 'edit' || formType === 'add'">
                     <p class="fieldLabel" :for="key">{{ key }}</p>
                     <k-input :id="key" style="width: 600px" class="inputField" :default-value="form[key] || ''" :show-clear-button="true" v-model="form[key]">
                     </k-input>
                  </div>
               </div>
            </div>

            <kbutton :theme-color="'primary'" class="save-button" @click="saveEditForm">
               <span v-if="saveLoading === false">Save</span>
               <loader style="color: #fff" v-if="saveLoading === true" :size="'small'" type="pulsing" />
            </kbutton>
         </div>
      </template>
   </VueSidePanel>
</template>

<script>
import { defineComponent, ref } from "vue";
// import { ComboBox } from '@progress/kendo-vue-dropdowns';
import { Button } from '@progress/kendo-vue-buttons';
import { Loader } from "@progress/kendo-vue-indicators";
import { Input } from '@progress/kendo-vue-inputs';
// import { Input, Checkbox } from '@progress/kendo-vue-inputs';
// import { DropDownList } from '@progress/kendo-vue-dropdowns';
// import { filterBy } from '@progress/kendo-data-query';

import { onClickOutside } from '@vueuse/core';

// import WorkflowListRender from '../shared/workflow-dropdown-list.vue';
// import { updateContacts } from "@/services";

const clearValueForm = {
   Name: false,
   type: false,
   reference: false,
   address: false,
   email: false,
   phone: false,
   taxNumber: false,
   bank_account: false,
   account_code: false,
   tracking: false,
   tax_code: false,
   workflow: false,
   region: false,
   country: false,
};

export default defineComponent({
   name: 'EditForm',

   components: {
      // 'combobox': ComboBox,
      'kbutton': Button,
      'k-input': Input,
      loader: Loader,
      // 'kcheckbox': Checkbox,
      // 'dropdownlist': DropDownList,
      // 'workflow-list-render': WorkflowListRender,
   },

   props: [
      'formFieldsValue',
      'handleOpenEditForm',
      'openEditFormFlag',
      'fetchContacts',
      'actionType',
   ],

   data() {
      return {
         error: null,
         workflows: [],
         persistedWorkflowsData: [],
         workflowDropdownOpened: false,
         selectedWorkflow: null,
         saveLoading: false,
         form: {
            ...this.formFieldsValue,
         },
         clearField: {
            ...clearValueForm,
         },
         exportId: 10,
         customerTypes: [
            { id: 0, type: 'All' },
            { id: 1, type: 'Supplier' },
            { id: 2, type: 'Customer' },
         ],
         workflowListRender: 'workflowListTemplate',
      };
   },

   setup(props) {
      const sideformforcontact2 = ref(null)
      onClickOutside(sideformforcontact2, (event) => {
         if (event.target.classList.contains('vsp-overlay')) {
            props.handleOpenEditForm(false);
         }
      });

      return {
         sideformforcontact2,
         isOpened: props.openEditFormFlag,
         closeEditFormPanel: props.handleOpenEditForm,
         fetchContactList: props.fetchContacts,
         formType: props.actionType,
      };
   },

   watch: {
      openEditFormFlag(val) {
         this.isOpened = val;

         if (val) {
            this.form = {
               ...this.formFieldsValue,
            };

            this.clearField = {
               ...clearValueForm,
            };

            this.selectedWorkflow = null;
         }
      },
      actionType(val) {
         if (val) {
            this.formType = val;

            if (val === 'batch' && !this.workflows.find((workflow) => workflow.name === 'Clear this field')) {
               this.workflows = [{ workflowID: 'Clear this field', name: 'Clear this field' }, ...this.workflows];
            }
         }
      },
   },

   methods: {
      close() {
         this.isOpened = false;
         this.closeEditFormPanel(false);
      },

      async saveEditForm() {
         this.$emit('saveEditForm', { type: this.formType, data: this.form})
         this.close()
      }
   }
});
</script>

<style scoped>
.side-panel-container {
   padding: 20px;
}
.side-panel-container .header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.side-panel-container .body {
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 20px 0;
   max-height: calc(100vh - 200px);
   overflow: hidden;
   overflow-y: auto;
}
.side-panel-container .body > div {
   width: 600px;
   margin: 0 auto;
}
.side-panel-container .fieldLabel {
   font-size: 15px;
   margin: 0;
   margin-bottom: 5px !important;
}
.side-panel-container .save-button {
   width: 60px;
   margin-top: 20px;
   margin-left: 28px;
}
.side-panel-container .inputField {
   margin-top: 4px;
}
.clear-checkbox {
   font-size: 14px !important;
   font-style: italic !important;
}
</style>
