<template>
  <div class="flex flex-column">
    <div class="flex gap-1" style="justify-content: end;">
      <k-button :theme-color="'success'" @click="handleOpenSingleAddForm(true)">
        <span>Add new Row</span>
      </k-button>
      <settings-dropdown :theme-color="'primary'" :items="settings" @itemclick="onSettingsItemClick">
        <span class="k-icon k-i-more-vertical k-icon-24"></span>
      </settings-dropdown>
    </div>

    <csvloader
      :hitAddNewData="hitAddNewData"
      :changeHitAddNewData="handleOpenSingleAddForm"
      :downloadCSV="downloadCSV"
      @updateDownloadCSVValue="(value) => downloadCSV = value"
    ></csvloader>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import csvloader from "../components/csvloader/index.vue";

export default defineComponent({
  name: "csv-loader",
  components: {
    csvloader: csvloader,
    "settings-dropdown": DropDownButton,
    "k-button": Button,
  },
  data() {
    return {
      settings: [
        {
          text: "Import",
        },
        {
          text: "Export",
        },
      ],
      workFlowId: "",
      state: "",
      activeTab: 0,
      hitAddNewData: false,
      downloadCSV: false,
      loader: false,
      exportId: 10,
    };
  },

  created() {
    this.workFlowId = this.$route.query.workflowid;
    this.state = this.$route.query.state;
    this.loader = this.$route.query.loader == ('true' || true) ? true : false;
    document.title = "EzzyBills CSV"
  },

  methods: {
    onSettingsItemClick(event) {
      event.item.text === "Import" ?
      this.$router.push(`/CSVLoader/Import?workflowid=${this.workFlowId}&state=${this.state}`)
      : this.export();
    },
    handleOpenSingleAddForm(flag) {
      this.hitAddNewData = flag;
    },
    async export() {
      this.downloadCSV = true;
    },
  },
});
</script>