<template>
   <tabs :selected="activeTab" @select="onSelectTab" :tabs="tabs">
      <template v-slot:All>
         <contacts v-if="activeTab === 0" :type="0"></contacts>
      </template>
      <template v-slot:Suppliers>
         <contacts v-if="activeTab === 1" :type="1"></contacts>
      </template>
      <template v-slot:Customers>
         <contacts v-if="activeTab === 2" :type="2"></contacts>
      </template>
   </tabs>
</template>

<script>
import { defineComponent } from "vue";
import { TabStrip } from '@progress/kendo-vue-layout';
import ContactsComponent from '../components/contacts/index.vue'

const initialTabs = [
   {
      title: 'All',
      content: 'All',
   },
   {
      title: 'Suppliers',
      content: 'Suppliers',
   },
   {
      title: 'Customers',
      content: 'Customers',
   },
];

export default defineComponent({
   name: 'ContactsPage',
   components: {
      'tabs': TabStrip,
      'contacts': ContactsComponent
   },
   data() {
      return {
         activeTab: 0,
         tabs: initialTabs,
      }
   },
   computed: {
   },
   watch: {
   },
   methods: {
      onSelectTab(event) {
         this.activeTab = event.selected;
      },
   },
   created() {
      document.title = "EzzyBills Contacts";
   },
})
</script>

<style scoped>
</style>
