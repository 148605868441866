<template>
    <AppBar class="appbar">
        <AppBarSection>
            <router-link active-class="active-text" to="/"><img src="https://ezzybills.ezzydoc.com/Images/ezzybills_logo_nav2.png" alt="ezzybills-logo" /></router-link>
        </AppBarSection>

        <AppBarSpacer :width="32" />

        <AppBarSection>
          <ul>
            <li>
              <router-link active-class="active-text" to="/">Home</router-link>
            </li>
            <li>
              <router-link active-class="active-text" to="/ContactsPage?export_id=2">Contacts</router-link> 
            </li>
            <li>
                <router-link active-class="active-text" to="/csvloader">CSV File Manager</router-link> 
            </li>
            <li>
                <router-link active-class="active-text" to="/ContactsPage2">Contacts2</router-link> 
            </li>
            <li>
                <router-link active-class="active-text" to="/AboutPage">About</router-link> 
            </li>
          </ul>
        </AppBarSection>
    </AppBar>

    <div class="layout">
        <router-view />
    </div>
</template>
<script>

// App style 
import "./App.scss";

import {
    AppBar,
    AppBarSection,
    AppBarSpacer,
} from '@progress/kendo-vue-layout';

export default {
    components: {
        AppBar,
        AppBarSection,
        AppBarSpacer,
    },
    data() {},
}
</script>
<style scoped>
    .appbar {
        background-color: #393A3D;
        justify-content: space-between;
        height: 65px;
    }
    .appbar .title {
        font-size: 18px;
        margin: 0;
    }
    .appbar ul {
        font-size: 14px;
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
    }
    .appbar ul li {
        margin: 0 10px;
    }
    .appbar li a {
        text-decoration: none;
        color: #393A3D;
        font-size: 16px;
    }
    .appbar li:hover {
        cursor: pointer;
        color: rgb(51, 153, 204);
    }
    .layout {
        padding: 15px;
    }
</style>