<template>
   <li class="k-item" @click="handleClick">
      <span>
         {{ dataItem?.Name }} {{ dataItem?.Code ? `(${dataItem?.Code})` : "" }}
      </span>
   </li>
</template>
<script>
export default {
   emits: {
      click: null
   },
   props: {
      dataItem: Object,
      textField: String,
      focused: Boolean,
      selected: Boolean,
   },
   methods: {
      handleClick(e) {
         this.$emit('click', e);
      }
   }
}
</script>