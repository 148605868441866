<template>
   <div class="block">
      <div class="prepare-file-import-block">
         <div class="flex justify-content-between align-items-center mb-20px">
            <h2>Step 1: Prepare file to import</h2>

            <!-- <div class="flex gap-1 align-items-center">
               <span class="k-icon k-i-question k-question-lg"></span>

               <a class="read-tutorial" href="https://www.ezzybills.com/user-guide/upload-contacts/"
                  target="_blank">Read the tutorial</a>
            </div> -->
         </div>
         <p>
            It is recommended to <b><a style="cursor: pointer; text-decoration: underline;" class="contact-anchor" @click="downloadTemplate">download the CSV template</a></b>
            or <b><a style="cursor: pointer; text-decoration: underline;" class="contact-anchor" @click="fetchExistingCSVFile">export existing CSV data</a></b>
            before importing.
            <br><br>
            Open the CSV file in Excel for editing, add new data or modify existing ones, and save the file.
            Please refrain from modifying the column headings as they are crucial for the import process.
         </p>
      </div>

      <div class="update-contacts-block">
         <h2 class="mb-20px">Step 2: Import File</h2>

         <div class="file-drop-block">
            <b>File to upload</b> (required)
            <div class="dropzone" @dragover.prevent @dragenter.prevent @dragstart.prevent
               @drop.prevent="handleFileChange($event.dataTransfer)">
               <input id="file-input" type="file" @change="handleFileChange($event.target)" required
                  ref="inputFileUpload" @click="clearFile" />

               <img width="80" src="../assets/drag-and-drop.jpg" alt="drag-and-drop" />
               <p for="file-input" class="file-input">Click or Drag and Drop CSV file here</p>
               <div class="align-center">
                  <h3 v-if="this.file">File name: {{ fileName }}
                     <k-button v-if="this.file" :size="small" :fill-mode="'flat'" :theme-color="'error'"
                        @click="clearFile"><span class="k-icon k-i-close k-icon-lg"></span></k-button>
                  </h3>

                  <p class="error">{{ error }}</p>
               </div>
            </div>
            <span class="size-note">*2 MB max size. File should end in .csv.</span>
         </div>

         <div v-if="!errorFound && this.file" class="table-block">
            <div v-if="!uploaded" class="flex flex-column justify-content-between" style="gap: 10px;">
               <p class="upload-text">The data below are ready to be imported (displaying only the first 50 rows)</p>
               <b class="error">Note: Please be aware that when you import the new data,
                  the existing data will be overwritten
                  and permanently replaced. We recommend reviewing the new data carefully to ensure it
                  contains the correct and up-to-date contact information before proceeding. Empty rows and rows without
                  the name will be ignored.</b>
            </div>

            <table v-if="!uploaded && tableRows.length > 0">
               <thead>
                  <tr>
                     <th v-for="header in headers" :key="header">{{ header }}</th>
                  </tr>
               </thead>
               <tbody>
                  <tr v-for="row in limitedTableRows" :key="row.id">
                     <td v-for="cell in row" :key="cell">{{ cell }}</td>
                  </tr>
               </tbody>
            </table>

            <div class="export-checkbox">
               <checkbox v-model="exportContacts" :label="'Export existing data before importing new data'" />
            </div>
            <k-button :size="'large'" :theme-color="'primary'" class="import-button" @click="uploadCsvData">
               <span v-if="uploading === false">Import</span>
               <loader style="color: #fff" v-if="uploading === true" :size="'small'" type="pulsing" />
            </k-button>
         </div>
      </div>
   </div>
</template>

<script>
import Papa from 'papaparse';
import { Button } from '@progress/kendo-vue-buttons';
import { Loader } from "@progress/kendo-vue-indicators";
import { Checkbox } from "@progress/kendo-vue-inputs";

import { stateColumns, dummyDataByState } from '../utils';

export default {
   name: 'CSVLoaderImport',
   components: {
      'k-button': Button,
      loader: Loader,
      checkbox: Checkbox
   },
   data() {
      return {
         workFlowId: "",
         state: "",
         headers: [],
         rows: [],
         file: null,
         fileName: "",
         exportContacts: false,
         error: "",
         errorFound: false,
         exportId: 10,
         uploading: false,
         uploaded: false,
      };
   },

   created() {
      this.state = this.$route.query.state;
      this.workFlowId = this.$route.query.workflowid;
      document.title = "EzzyBills CSV"
   },

   computed: {
      limitedTableRows() {
         return this.tableRows.slice(0, 50);
      },
      tableRows() {
         return this.rows;
      }
   },
   methods: {
      handleFileChange(event) {
         this.file = event.files[0];
         this.fileName = this.file.name;
         // File extension validation
         if (!this.file.type.includes('csv')) {
            this.errorFound = true;
            this.error = "The file must be in csv format.";
            return;
         }
         // File size validation - 2MB
         if (this.file.size / 1024 / 1024 > 2) {
            this.errorFound = true;
            this.error = "The file size must be equal and less than 2MB.";
            return;
         }

         const areArraysEqual = (incomingArr) => {
            const defaultArr = stateColumns[this.state]
            if (defaultArr) {
               if (incomingArr.length !== defaultArr.length) {
                  return false;
               }
   
               for (let i = 0; i < incomingArr.length; i++) {
                  if (!defaultArr[i].includes(incomingArr[i])) {
                     return false;
                  }
               }
   
               return true;
            } else {
               return false;
            }
         }

         let headers = []
         let items = []

         const setCSVData = (headers, items) => {
            this.headers = headers
            this.rows = items
         }

         Papa.parse(this.file, {
            header: true,
            complete: (results) => {
               let error = "";

               headers = results.meta.fields;

               const isSame = areArraysEqual(headers)
               if (!isSame) {
                  error = "The headers is not matched!";
               } else {
                  let rows = results.data
                  if (rows && rows.length) {

                     rows.map(row => {
                        if (row && Object.keys(row) && Object.keys(row)[0] && row[Object.keys(row)[0]] && row[Object.keys(row)[0]] !== '') {
                           items.push(row)
                        }
                     })

                     setCSVData(headers, items)
                  } else {
                     error = "The data is not found!";
                  }
               }

               if (error.length > 0) {
                  this.errorFound = true;
                  this.error = error;
               }
            }
         });
      },

      async convertToBytes(csvData) {
         const blob = new Blob([csvData], { type: 'text/csv' });
         return new Uint8Array(await blob.arrayBuffer());
      },

      async uploadCsvData() {
         try {
            this.uploading = true;
   
            if (this.exportContacts) {
               this.fetchExistingCSVFile();
            }

            const csvData = Papa.unparse(this.rows)
            const bytesData = await this.convertToBytes(csvData)
            const body = Object.values(bytesData)

            const url = `${process.env.VUE_APP_BASE_URL}addWorkflowCSV?APIKey=${process.env.VUE_APP_API_KEY}&state=${this.state}&workflowid=${this.workFlowId}`

            const options = {
               method: "POST",
               mode: "cors",
               headers: new Headers({'content-type': 'application/json', 'accept': 'application/json'}),
               credentials: "include",
               body: JSON.stringify({
                  "PictureName": this.fileName,
                  "PictureStream": body
               }),
            };

            await fetch(url, options)
               .then((response) => response.json())
               .then(async (data) => {
                  if (data && data.invoice_id) {
                     console.log("data: ", data)
                     this.$router.push(`/csvloader?workflowid=${this.workFlowId}&state=${this.state}&loader=true`)
                  }
               })
               .catch((error) => {
                  console.log("error", error)
                  this.error = error.message
               });
         } catch (error) {
            console.log("error: ", error);
            this.error = error.message
         }
      },

      clearFile() {
         this.file = null;
         this.fileName = "";
         this.rows = [];
         this.headers = [];
         this.error = "";
         this.errorFound = false;
         this.uploaded = false;
         this.uploading = false;
         this.exportContacts = false;
         this.$refs.inputFileUpload.value = '';
      },


      async downloadTemplate() {
         try {
            const dummyData = dummyDataByState[this.state];

            const csvData = Papa.unparse(dummyData, { header: true })

            var blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) {
               navigator.msSaveBlob(blob, 'exported_data');
            } else {
               var link = document.createElement("a");
               if (link.download !== undefined) {
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", 'csv_data_template');
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
               }
            }

         } catch (error) {
            console.log("error: ", error);
         }
      },

      async fetchExistingCSVFile() {
         try {
            const url = `${process.env.VUE_APP_BASE_URL}getWorkflowSettings?workflowid=${this.workFlowId}&APIKey=${process.env.VUE_APP_API_KEY}`;
            const options = {
               method: "GET",
               mode: "cors",
               credentials: "include",
               headers: {
                  Accept: "application/json",
               },
            };

            await fetch(url, options)
               .then((response) => response.json())
               .then(async (data) => {
                  if (data && data.setting && data.setting.length) {
                     const parsedData = JSON.parse(data.setting)
                     // console.log("parsedData: ", parsedData)
                     const matchedStateData = parsedData.settings.find(setting => setting.state == this.state)
                     if (matchedStateData) {
                        if (matchedStateData.keywords && matchedStateData.keywords[1] && matchedStateData.keywords[1].value && matchedStateData.keywords[0] && matchedStateData.keywords[0].value) {
                           this.fileName = matchedStateData.keywords[0].value
                           this.invoiceId = matchedStateData.keywords[1].value
                           await this.fetchExistingCSVFileURL(this.invoiceId)
                        } else {
                           console.log("Invalid invoice_id")
                           throw new Error("Invalid invoice_id")
                        }
                     } else {
                        console.log("File not found!")
                        throw new Error("File not found!")
                     }
                  }
               })
               .catch((error) => console.log("error", error));
         } catch (error) {
            console.log("error: ", error);
         }
      },

      async fetchExistingCSVFileURL(invoiceid) {
         try {
            const url = `${process.env.VUE_APP_BASE_URL}getFormData?APIKey=${process.env.VUE_APP_API_KEY}&invoiceid=${invoiceid}`
            const options = {
               method: "GET",
               mode: "cors",
               credentials: "include",
               headers: {
                  Accept: "application/json",
               },
            };

            await fetch(url, options)
               .then((response) => response.json())
               .then(async (data) => {
                  // console.log("data: ", data)
                  if (data && data.blob_url && data.blob_url.length) {
                     // console.log("data.blob_url: ", data.blob_url)
                     await this.parseCSVFile(data.blob_url)
                  } else {
                     console.log("blob_url not found!")
                  }
               })
               .catch((error) => console.log("error", error));
         } catch (error) {
            console.log("error: ", error);
         }
      },

      async parseCSVFile(url) {
         try {
            let items = []

            Papa.parse(url, {
               download: true,
               header: true,
               step: function(row) {
                  if (row && row.data && Object.keys(row.data) && Object.keys(row.data)[0] && row.data[Object.keys(row.data)[0]] && row.data[Object.keys(row.data)[0]] !== '') {
                     items.push({
                        ...row.data
                     })
                  }
               },
               complete: function() {
                  if (items.length) {
                     // const data = items.filter(item => delete item['selected'])
                     const csvData = Papa.unparse(items, { header: true })

                     var blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                     if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(blob, 'exported_data');
                     } else {
                        var link = document.createElement("a");
                        if (link.download !== undefined) {
                           var url = URL.createObjectURL(blob);
                           link.setAttribute("href", url);
                           link.setAttribute("download", 'exported_data');
                           link.style.visibility = 'hidden';
                           document.body.appendChild(link);
                           link.click();
                           document.body.removeChild(link);
                        }
                     }
                  }
               }
            });
         } catch (error) {
            console.log("error: ", error);
         }
      },
   }
};
</script>
<style scoped>
p {
   font-size: 15px;
   line-height: 24px;
}
h2 {
   color: #0c4271;
   font-weight: 700;
   font-size: 26px;
   margin: 0;
   padding: 0;
}
.mb-20px {
   margin-bottom: 20px;
}
.block {
   margin: auto;
   width: 900px;
}
/* Prepare file import block */
.prepare-file-import-block {
   margin: 30px 0;
}
.download-template {
   width: 270px;
   height: 40px;
   font-weight: bold;
}
.read-tutorial, .contact-anchor {
   color: blue;
   font-weight: 700;
}
/* File upload block */
.update-contacts-block {
   margin: 50px 0;
}
.file-drop-block {
   margin: 10px 0;
}
.dropzone {
  height: fit-content;
  min-height: 200px;
  max-height: 500px;
  height: auto;
  width: 860px;
  background: #fdfdfd;
  border-radius: 5px;
  border: 2px dashed darkgrey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  padding: 20px;
}
.file-input {
   margin-top: 8px;
   font-size: 16px;
}
input[type="file"] {
  position: absolute;
  opacity: 0;
  width: inherit;
  min-height: 200px;
  max-height: 400px;
  cursor: pointer;
}
.export-checkbox {
   border: 1px solid darkgrey;
   background: #fdfdfd;
   border-radius: 5px;
   padding: 15px;
   margin: 10px 0;
}
.size-note {
   color: slategrey;
   font-size: 12px;
}
.table-block {
   margin: 10px 0;
   display: flex;
   flex-direction: column;
   gap: 8px;
}
table {
   margin: 10px auto;
   color: #333;
   background: white;
   border: 1px solid grey;
   font-size: 14px;
   border-collapse: collapse;
   display: block;
   overflow-x: auto;
   white-space: normal;
   max-width: 900px;
   table-layout: fixed;  /* Add this property */
}
table thead th {
   color: #777;
   background: rgba(0, 0, 0, .1);
}
table th,
table td {
   min-width: 100px;
   padding: .5em;
   border: 1px solid lightgrey;
   word-break: break-word; /* Add this property */
   overflow-wrap: break-word; /* Add this property */
}
.upload-text {
   font-size: 16px;
   padding: 0;
   margin: 0;
}
.import-button {
   width: 100px;
}
</style>