<template>
   <VueSidePanel v-model="isOpened" lock-scroll width="600px" rerender hide-close-btn ref="sideform">
      <!-- Content of the VueSidePanel component -->
     <template #header>
         <div class="side-panel-container">
            <div class="header">
               <h2 v-if="!selectedContact"> Batch Edit </h2>
               <h2 v-if="selectedContact"> {{ selectedContact.Name }} </h2>
               <kbutton :fill-mode="'outline'" :theme-color="'error'" @click="close"><span class="k-icon k-i-close k-icon-lg"></span></kbutton>
            </div>
            <div class="body">
               <div>
                  <p class="fieldLabel">Workflow</p>
                  <span @click="workflowDropdownOpened = true">
                     <combobox 
                        style="width: 600px;"
                        :opened="workflowDropdownOpened"
                        :onFocus="() => { workflowDropdownOpened = true; }"
                        :onBlur="() => { workflowDropdownOpened = false; }"
                        :data-items="selectedContact ? workflowsData.filter(wf => wf.name !== 'Clear this field') : workflowsData" 
                        :value="selectedWorkflow?.name || null"
                        :item-render="workflowListRender"
                        :allow-custom="true"
                        :filterable="true"
                        @filterchange="onWorkflowFilterChange"
                        @change="onWorkflowChange"
                     >
                        <template v-slot:workflowListTemplate="{ props }">
                           <workflow-list-render 
                              :style="props.style"
                              :class="props.itemClass" 
                              :data-item="props.dataItem"
                              @click="(ev) => props.onClick(ev)" />
                        </template>
                     </combobox>
                  </span>
               </div>

               <div>
                  <p class="fieldLabel">Tracking</p>
                  <multiselect
                     style="width: 600px;"
                     :data-items="selectedContact ? trackingsData.filter(tracking => tracking !== 'Clear this field') : trackingsData" 
                     @filterchange="onTrackingFilterChange"
                     :filterable="true"   
                     @change="onTrackingChange"
                     :default-value="selectedTracking"
                  ></multiselect>
               </div>

               <div>
                  <p class="fieldLabel">GL Account</p>
                  <span @click="glAccountDropdownOpened = true;">
                     <combobox 
                        style="width: 600px;"
                        :opened="glAccountDropdownOpened"
                        :onFocus="() => { glAccountDropdownOpened = true; }"
                        :onBlur="() => { glAccountDropdownOpened = false; }"
                        :data-items="selectedContact ? glAccountsData.filter(glAccount => glAccount.Name !== 'Clear this field') : glAccountsData" 
                        :value="selectedGlAccount ? `${selectedGlAccount?.Name || ''} ${selectedGlAccount?.Code ? `(${selectedGlAccount?.Code})` : ''}` : null"
                        :item-render="glaccountListRender"
                        :allow-custom="true"
                        :filterable="true"
                        @filterchange="onGlAccountFilterChange"
                        @change="onGlAccountChange"
                     >
                        <template v-slot:glaccountListTemplate="{ props }">
                           <glaccount-list-render 
                              :style="props.style"
                              :class="props.itemClass" 
                              :data-item="props.dataItem"
                              @click="(ev) => props.onClick(ev)" />
                        </template>
                     </combobox>
                  </span>
               </div>

               <div>
                  <p class="fieldLabel">Tax Code</p>
                  <kcheckbox class="clear-checkbox" v-if="!selectedContact" :value="clearTaxCode"
                     @change="onClearTaxFieldChange" label="Clear this field" />
                  <k-input 
                     style="width: 600px;"
                     class="inputField"
                     :disabled="clearTaxCode"
                     v-model="taxcode"
                     :default-value="taxcode || ''"
                     :show-clear-button="true"
                  >
                  </k-input>
               </div>

               <div v-if="showTaxNumber && !isItBatchEditForm">
                  <p class="fieldLabel">Business Number</p>
                  <kcheckbox class="clear-checkbox" v-if="!selectedContact" :value="clearTaxNumber"
                     @change="onClearTaxNumberFieldChange" label="Clear this field" />
                  <k-input 
                     style="width: 600px;"
                     class="inputField"
                     :disabled="clearTaxNumber"
                     v-model="taxNumber"
                     :default-value="taxNumber || ''"
                     :show-clear-button="true"
                  >
                  </k-input>
               </div>

               <div>
                  <p class="fieldLabel">Tags</p>
                  <kcheckbox class="clear-checkbox" v-if="!selectedContact" :value="clearTag"
                     @change="onClearTagFieldChange" label="Clear this field" />
                  <k-input 
                     style="width: 600px;"
                     class="inputField"
                     :disabled="clearTag"
                     v-model="tags"
                     :default-value="tags || ''"
                     :show-clear-button="true"
                     placeholder="Enter 1 or multiple text blocks from the invoice, separating by |"
                  >
                  </k-input>
               </div>

               <kbutton :theme-color="'primary'" class="save-button" @click="saveEditForm">
                  <span v-if="saveLoading === false">Save</span> 
                  <loader style="color: #fff;" v-if="saveLoading === true" :size="'small'" type="pulsing" />
               </kbutton>
            </div>
         </div>
     </template>
   </VueSidePanel>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

import { ComboBox, MultiSelect } from '@progress/kendo-vue-dropdowns';

import { Button } from '@progress/kendo-vue-buttons';
import { Loader } from "@progress/kendo-vue-indicators";
import { Input, Checkbox } from '@progress/kendo-vue-inputs';
import { filterBy } from '@progress/kendo-data-query';
import { useRoute } from 'vue-router';

import { onClickOutside } from '@vueuse/core';
import _ from 'lodash';

import GlAccountListRender from '../shared/glaccount-dropdown-list.vue';
import WorkflowListRender from '../shared/workflow-dropdown-list.vue';

export default defineComponent({
   name: 'EditForm',
   components: {
      'combobox': ComboBox,
      'kbutton': Button,
      'k-input': Input,
      'kcheckbox': Checkbox,
      'glaccount-list-render': GlAccountListRender,
      'workflow-list-render': WorkflowListRender,
      'multiselect': MultiSelect,
      loader: Loader,
   },
   props: [
      'handleOpenEditForm', 
      'openEditFormFlag', 
      'saveEdit', 
      'workflows', 
      'glAccounts',
      'trackings',
      'fetchContacts',
      'isBatchEditForm',
      'selectedContactToEdit'
   ], 
   data() {
      return {
         saveLoading: false,
         selectedWorkflow: null,
         workflowDropdownOpened: false,
         selectedGlAccount: null,
         glAccountDropdownOpened: false,
         selectedTracking: [],
         taxcode: "",
         taxNumber: "",
         tags: "",
         clearTaxCode: false,
         clearTaxNumber: false,
         clearTag: false,
         trackingsData: [],
         persistedTrackingsData: [],
         workflowsData: [],
         persistedWorkflowsData: [],
         glAccountsData: [],
         persistedGlAccountsData: []
      };
   },
   setup(props) {
      const sideform = ref(null)
      const showTaxNumber = ref(false);

      const route = useRoute();
      const queryParams = route.query;
      const showabn = queryParams.showabn;
      showTaxNumber.value = showabn === 'true';

      watch(
         () => route.fullPath,
         async () => {
            const queryParams = route.query;
            const showabn = queryParams.showabn;
            showTaxNumber.value = showabn === 'true';
         }
      );

      watch(() => props.openEditFormFlag, (val) => {
         if (val) {
            const queryParams = route.query;
            const showabn = queryParams.showabn;
            showTaxNumber.value = showabn === 'true';
         }
      });

      onClickOutside(sideform, (event) => {
         if (event.target.classList.contains('vsp-overlay')) {
            props.handleOpenEditForm(false);
         }
      });

      return {
         sideform,
         showTaxNumber,
         glaccountListRender: 'glaccountListTemplate',
         workflowListRender: 'workflowListTemplate',
         isOpened: props.openEditFormFlag,
         closeEditFormPanel: props.handleOpenEditForm,
         save: props.saveEdit,
         fetchContactList: props.fetchContacts,
         isItBatchEditForm: props.isBatchEditForm,
         selectedContact: props.selectedContactToEdit
      }
   },
   watch: {
      openEditFormFlag(val) {
         this.isOpened = val;

         if (val) {
            this.selectedContact = null;
            this.selectedGlAccount = null;
            this.selectedWorkflow = null;
            this.selectedTracking = null;
            this.taxcode = null;
            this.tags = null;
            this.taxNumber = null;
            this.clearTaxNumber = false;
            this.clearTaxCode = false;
            this.clearTag = false;
         }
      },
      selectedContactToEdit(val) {
         if (val) {
            this.selectedContact = val;
            this.selectedWorkflow = this.workflowsData.find((wf) => wf.workflowID === this.selectedContact?.workflow) || null;
            this.selectedGlAccount = this.glAccountsData.find((gl) => gl.Code === this.selectedContact?.account_code) || null;

            if (this.selectedContact?.tracking) {
               this.selectedTracking = this.selectedContact.tracking.split("|")
            } else {
               this.selectedTracking = null;
            }

            if (this.selectedContact?.tax_code) {
               this.taxcode = this.selectedContact?.tax_code;
            } else {
               this.taxcode = null;
            }

            if (this.selectedContact?.tags) {
               this.tags = this.selectedContact?.tags;
            } else {
               this.tags = null;
            }

            if (this.selectedContact?.taxNumber) {
               this.taxNumber = this.selectedContact?.taxNumber;
            } else {
               this.taxNumber = null;
            }
         } 
      },
      workflows(val) {
         this.workflowsData = [{ workflowID: 'Clear this field', name: 'Clear this field' }, ...val];
         this.persistedWorkflowsData = [{ workflowID: 'Clear this field', name: 'Clear this field' }, ...val];
      },
      trackings(val) {
         this.trackingsData = [ 'Clear this field', ...val];
         this.persistedTrackingsData = [ 'Clear this field', ...val];
      },
      glAccounts(val) {
         this.glAccountsData = [{ Code: undefined, Name: 'Clear this field' }, ...val];
         this.persistedGlAccountsData = [{ Code: undefined, Name: 'Clear this field' }, ...val];
      },
   },
   methods: {
      close() {
         this.isOpened = false;
         this.closeEditFormPanel(false);
      },
      onWorkflowChange(event) {
         this.selectedWorkflow = event.target.value;
         this.workflowDropdownOpened = false;
      },
      onGlAccountChange(event) {
         this.selectedGlAccount = event.target.value;
         this.glAccountDropdownOpened = false;
      },
      onTrackingChange(event) {
         this.selectedTracking = event.target.value.length === 0 ? null : event.target.value;
      },
      onClearTaxFieldChange(event) {
         this.clearTaxCode = event.value;
      },
      onClearTaxNumberFieldChange(event) {
         this.clearTaxNumber = event.value;
      },
      onClearTagFieldChange(event) {
         this.clearTag = event.value;
      },
      onTrackingFilterChange(event) {
         this.trackingsData = filterBy(this.persistedTrackingsData.slice(), event.filter);
      },
      onGlAccountFilterChange(event) {
         const codeFilter = { ...event.filter, field: 'Code' };
         const nameFilter = { ...event.filter, field: 'Name' };
         this.glAccountsData = _.uniqBy([...filterBy(this.persistedGlAccountsData.slice(), nameFilter), ...filterBy(this.persistedGlAccountsData.slice(), codeFilter)], function (e) {
            return e.Code;
         });
      },
      onWorkflowFilterChange(event) {
         const filter = { ...event.filter, field: 'name' };
         this.workflowsData = filterBy(this.persistedWorkflowsData.slice(), filter);
      },
      async saveEditForm() {
         this.saveLoading = true;
         const formData = {};
         if (this.selectedWorkflow) {
            formData.workflow = this.selectedWorkflow.workflowID === 'Clear this field' ? 0 : this.selectedWorkflow.workflowID;
         } else if (this.selectedContact) {
            formData.workflow = 0;
         }
         if (this.selectedGlAccount) {
            formData.account_code = this.selectedGlAccount.Name === 'Clear this field' ? null : this.selectedGlAccount.Code;
         } else if (this.selectedContact) {
            formData.account_code = null;
         }
         if (this.selectedTracking) {
            let arr = this.selectedTracking;

            if (arr.length > 0) {
               if (arr.includes('Clear this field') && arr.length > 1) {
                  arr = arr.filter((x) => x !== 'Clear this field');
               }

               if (arr.length === 1 && arr[0] === 'Clear this field') {
                  formData.tracking = null;
               } else {
                  formData.tracking = arr.join('|');
               }
            } else {
               formData.tracking = null;
            }
         } else if (this.selectedContact) {
            formData.tracking = null;
         }
         if (this.clearTaxCode) {
            formData.tax_code = "";
         } else if (this.taxcode && this.taxcode?.length > 0) {
            formData.tax_code = this.taxcode;
         } else if (this.selectedContact) {
            formData.tax_code = "";
         }
         if (this.clearTaxNumber) {
            formData.taxNumber = "";
         } else if (this.taxNumber && this.taxNumber?.length > 0) {
            formData.taxNumber = this.taxNumber;
         } else if (this.selectedContact) {
            formData.taxNumber = "";
         }

         if (this.clearTag) {
            formData.tags = "";
         } else if (this.tags && this.tags?.length > 0) {
            formData.tags = this.tags;
         } else if (this.selectedContact) {
            formData.tags = "";
         }

         await this.save({ 
            ...formData
         });
         setTimeout(() => {
            this.saveLoading = false;
            this.closeEditFormPanel(false);
            this.fetchContactList();
         }, 500);
      },
   },
});
</script>

<style scoped>
.side-panel-container {
   padding: 20px;  
}
.side-panel-container .header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.side-panel-container .body {
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 20px 0;
   width: 600px;
   margin: auto;
}
.side-panel-container .fieldLabel {
   font-size: 15px;
   margin-bottom: 5px !important;
   padding: 0;
}
.side-panel-container .inputField {
   margin-top: 4px;
   padding: 3px 0px;
}
.side-panel-container .save-button {
   width: 60px;
   margin-top: 10px;
}
.clear-checkbox {
   font-size: 14px !important;
   font-style: italic !important;
}
</style>