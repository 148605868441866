import { createWebHashHistory, createRouter } from "vue-router";
import HomePage from './pages/HomePage.vue'
import AboutPage from './pages/AboutPage.vue'
import NotFound from './pages/NotFound.vue'
import ContactsPage from './pages/ContactsPage.vue'
import ContactsPage2 from './pages/ContactsPage2.vue'
import Contacts2Import from './pages/Contacts2Import.vue'
import csvloader from './pages/csvloader.vue'
import CSVLoaderImport from './pages/CSVLoaderImport.vue'

const routes =  [
  { path: '/', component: HomePage },
  { path: '/AboutPage', component: AboutPage },
  { path: '/ContactsPage', component: ContactsPage },
  { 
    path: '/ContactsPage2', 
    component: ContactsPage2,
  },
  {
    path: '/ContactsPage2/Import', 
    component: Contacts2Import,
  },
  { path: '/csvloader', component: csvloader },
  { path: "/:catchAll(.*)", component: NotFound },
  {
    name: 'CSVLoaderImport',
    path: '/CSVLoader/Import', 
    component: CSVLoaderImport,
  },
  {
    name: 'TablePage',
    path: '/TablePage', 
    component: () => import('./pages/TablePage.vue'),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router